import React from 'react';
import 'leaflet-draw';
import PropTypes, { element } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import Skeleton from '@material-ui/lab/Skeleton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import img7 from './../../assets/images/marker/waypoint/flag-green.png';
import img8 from './../../assets/images/marker/waypoint/flag-red.png';

import SliderComponent from '../../components/Slider/barSlider';
import Charts from './charts/Charts'

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
    },
    table: {
        width: '100%',
        marginTop: '1rem',
        marginBottom: '1rem',
        overflow: 'hidden',
    },
    tableRow: {
        backgroundColor: theme.palette.background.paper,
        '& > *': {
            borderBottom: 'unset',
            padding: '0.5rem',
        },
        '&:not(:last-child)': {
            borderBottom: `2px solid ${theme.palette.background.default}`,
        },
    },
    tableCell: {
        textAlign: 'center',
        padding: '0',
    },
    expandedRow: {
        '& > *': {
            borderBottom: 'none',
        },
    },
    expandedContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: theme.palette.action.selected,
        '& > div': {
            padding: '1rem',
        },
        margin: `1rem`,
        padding: '1rem',
    },
    chartContainer: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '1rem',
        '&:not(:last-child)': {
            marginRight: '1rem',
        },
        height: '12rem',
    },
    sliderContainer: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '1rem',
        '&:not(:last-child)': {
            marginRight: '1rem',
        },
        height: '12rem',
        maxWidth: '16rem',
    },
    chartDoughnutContainer: {
        flex: 1,
        height: '12rem',
        minWidth: '22.5rem',
    },
    secondary: {
        backgroundColor: '#2D3238',
    },
    primary: {
        backgroundColor: '#3f51b5',
    },
    card: {
        flex: 1,
        padding: '1rem',
        backgroundColor: '#fff',
    },
});

class QualityDashboard extends React.Component {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        const previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());

        if (previousDate.getDate() != currentDate.getDate()) {
            previousDate.setDate(0);
        }

        const dataFim = currentDate.toISOString().split('T')[0];
        const dataInicio = previousDate.toISOString().split('T')[0];

        this.state = {
            allJobs: [],
            jobDetail: [],
            allJobDetail: [],
            configurationDialog: false,
            filterDialog: false,
            confirmationDialog: false,
            expandedRows: [],
            selectedWork: null,
            jobStatus: {},
            jobLinkStatus: [],
            jobLink: [],
            countJobAprovado: 0,
            countJobEmAnalise: 0,
            countJobRetrabalho: 0,
            countJobRejeitado: 0,
            selectedAlerts: [],
            allAlerts: {},
            temporaryAlerts: [],
            filterValues: {
                nome: '',
                dataInicio: dataInicio,
                dataFim: dataFim,
                status: ['aprovado', 'retrabalho', 'analise', 'rejeitado'],
                acertoRange: [0, 100],
                uniformidadeRange: [0, 100],
                falhaRange: [0, 100],
                perdaRange: [0, 100],
                velocidadeRange: [0, 100],
                alturaRange: [0, 100],
                fluxoRange: [0, 100],
                temperaturaRange: [0, 100],
                umidadeRange: [0, 100],
                ventoRange: [0, 100],
                deltaTRange: [0, 100],
            },
            alertValidationError: null,
            alertCounts: {
                erro: 0,
                atencao: 0,
                normal: 0,
                invalido: 0,
            },
            isLoading: true,
            filteredStartDate: '',
            totalAreaPolygon: '',
            totalSpray: '',
        };
    }

    componentDidMount = () => {
        this.readJobLink();
        this.readAlerts();
    };

    componentDidUpdate() { };

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };

        clearInterval(this.timer);
    };

    readJobLink = () => {
        const apiUrl = Config.server + `/job_link`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                const jobStatus = {};
                const filterJobs = new Map();

                result.forEach(job => {
                    if (job.guid && job.status !== 'canceled') {
                        const validJobs = filterJobs.get(job.id_job);
                        if (!validJobs || job.id > validJobs.id) {
                            filterJobs.set(job.id_job, job);
                        }
                    }
                    jobStatus[job.id] = job.statusJob;
                });
                const allValidJobs = Array.from(filterJobs.values());

                this.setState({
                    allJobs: allValidJobs,
                    jobStatus: jobStatus,
                });
                this.readJobs(this.state.filterValues.dataInicio);
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    readJobs = (startDate) => {
        let dateIniObj = new Date(startDate + "T00:00:00Z");
        let dateEndObj = new Date(this.state.filterValues.dataFim + "T23:59:59Z");
        let dateIniTimestamp = dateIniObj.getTime();
        let dateEndTimestamp = dateEndObj.getTime();

        const apiUrl = `${Config.server}/job_detail/${encodeURIComponent(dateIniTimestamp)}/${encodeURIComponent(dateEndTimestamp)}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                const allJobsLinkIds = this.state.allJobs.map(link => link.id);
                const filteredAllJobs = result.filter(job => allJobsLinkIds.includes(job.id_job_link));
                const addFilteredAllJobs = filteredAllJobs.map(job => ({
                    ...job,
                    statusJob: this.state.jobStatus[job.id_job_link] || 'analise'
                }));

                const updatedJobDetails = addFilteredAllJobs.filter(detail =>
                    this.state.allJobs.some(job => detail.id_job_link === job.id)
                );
                const statusCounts = this.calculateJobStatusCounts(updatedJobDetails);

                this.setState({
                    jobDetail: addFilteredAllJobs,
                    allJobDetail: addFilteredAllJobs,
                    countJobAprovado: statusCounts.countJobAprovado,
                    countJobEmAnalise: statusCounts.countJobEmAnalise,
                    countJobRetrabalho: statusCounts.countJobRetrabalho,
                    countJobRejeitado: statusCounts.countJobRejeitado,
                    filteredStartDate: this.state.filterValues.dataInicio,
                });
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 2000);
                this.previousFilters(addFilteredAllJobs);
                this.applyFilters();
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    handleClickOpen = () => {
        this.setState(prevState => ({
            configurationDialog: true,
            temporaryAlerts: [...prevState.selectedAlerts]
        }));
    };

    handleClose = () => {
        this.setState({ configurationDialog: false, alertValidationError: null });
    };

    handleClickOpenFilter = () => {
        this.setState({ filterDialog: true });
    };

    handleCloseFilter = () => {
        this.setState({ filterDialog: false });
    };

    toggleRow = (id) => {
        this.setState(prevState => ({
            expandedRows: prevState.expandedRows.includes(id)
                ? prevState.expandedRows.filter(rowId => rowId !== id)
                : [...prevState.expandedRows, id]
        }));
    };

    sortAlerts = (alerts) => {
        return alerts.sort((a, b) => {
            if (a.qualityData < b.qualityData) {
                return -1;
            }
            if (a.qualityData > b.qualityData) {
                return 1;
            }
            return 0;
        });
    }

    handleAddAlert = () => {
        this.setState(prevState => ({
            temporaryAlerts: [
                ...prevState.temporaryAlerts,
                { id: Date.now(), qualityData: '', minimum: '', maximum: '', alertLevel: '' }
            ],
            alertValidationError: null
        }));
    };

    handleDeleteAlert = (indexAlert) => {
        this.setState(prevState => {
            const newTemporaryAlerts = prevState.temporaryAlerts.filter((_, index) => index !== indexAlert);
            return { temporaryAlerts: this.sortAlerts(newTemporaryAlerts) };
        });
        notification.open({
            message: this.props.res.ATENCAO,
            description: this.props.res.CONFIRME_EXCLUSAO,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
        });
    };

    handleAlertChange = (index, field, value) => {
        this.setState(prevState => {
            const newTemporaryAlerts = [...prevState.temporaryAlerts];
            newTemporaryAlerts[index] = { ...newTemporaryAlerts[index], [field]: value };
            return { temporaryAlerts: newTemporaryAlerts };
        });
    };

    getAlertLevel = (metric, value) => {
        const alertPriority = {
            erro: 3,
            atencao: 2,
            normal: 1
        };

        const alertConfigurations = this.state.selectedAlerts.filter(alert => alert.qualityData === metric);

        let maxAlertLevel = 'normal';

        alertConfigurations.forEach(alert => {
            const minValue = parseFloat(alert.minimum);
            const maxValue = parseFloat(alert.maximum);
            if (value >= minValue && value <= maxValue) {
                if (alertPriority[alert.alertLevel.toLowerCase()] > alertPriority[maxAlertLevel]) {
                    maxAlertLevel = alert.alertLevel.toLowerCase();
                }
            }
        });

        return maxAlertLevel;
    };

    renderAlertsInputs = () => {
        const { classes, res } = this.props;
        const { temporaryAlerts, alertValidationError } = this.state;
        const alerts = temporaryAlerts || [];

        return alerts.map((alert, index) => (
            <div style={{ margin: '1rem 0', display: 'flex', justifyContent: 'space-between' }} key={index}>
                <FormControl className={classes.margin} style={{ marginTop: '0.5rem', marginRight: '0.5rem', padding: '0.5rem 0', width: '12rem' }}>
                    <InputLabel htmlFor={`quality-data-${index}`} style={{ whiteSpace: 'nowrap' }}>{res.DADO_QUALIDADE}</InputLabel>
                    <NativeSelect
                        value={alert.qualityData}
                        onChange={(e) => this.handleAlertChange(index, 'qualityData', e.target.value)}
                        style={{ marginTop: '0.5rem' }}
                        error={alertValidationError && alertValidationError.id === alert.id}
                    >
                        <option value=""></option>
                        <option value="acerto">{res.ACERTO_}</option>
                        <option value="uniformidade">{res.UNIFORMIDADE}</option>
                        <option value="falha">{res.FALHA_}</option>
                        <option value="perda">{res.PERDA}</option>
                        <option value="velocidade">{res.VELOCIDADE}</option>
                        <option value="altura">{res.ALTURA}</option>
                        <option value="fluxo">{res.FLUXO}</option>
                        <option value="temperatura">{res.TEMPERATURA_}</option>
                        <option value="umidade">{res.UMIDADE_}</option>
                        <option value="vento">{res.VENTO}</option>
                        <option value="deltaT">{res.DELTA_T}</option>
                    </NativeSelect>
                    {alertValidationError && alertValidationError.id === alert.id && (
                        <FormHelperText error>{res.DADO_INCORRETO}</FormHelperText>
                    )}
                </FormControl>
                <FormControl style={{ marginRight: '0.5rem', padding: '0.5rem 0', width: '8rem' }}>
                    <TextField
                        label={res.MINIMO}
                        type="number"
                        value={alert.minimum}
                        onChange={(e) => this.handleAlertChange(index, 'minimum', e.target.value)}
                    />
                </FormControl>
                <FormControl style={{ marginRight: '0.5rem', padding: '0.5rem 0', width: '8rem' }}>
                    <TextField
                        label={res.MAXIMO}
                        type="number"
                        value={alert.maximum}
                        onChange={(e) => this.handleAlertChange(index, 'maximum', e.target.value)}
                    />
                </FormControl>
                <FormControl className={classes.margin} style={{ marginTop: '0.5rem', marginRight: '0.5rem', padding: '0.5rem 0', width: '12rem' }}>
                    <InputLabel htmlFor={`alert-level-${index}`} style={{ whiteSpace: 'nowrap' }}>{res.NIVEL_ALERTA}</InputLabel>
                    <NativeSelect
                        value={alert.alertLevel}
                        onChange={(e) => this.handleAlertChange(index, 'alertLevel', e.target.value)}
                        style={{ marginTop: '0.5rem' }}
                        error={alertValidationError && alertValidationError.id === alert.id}
                    >
                        <option value=""></option>
                        <option value="atencao">{res.ATENCAO}</option>
                        <option value="erro">{res.PROBLEMA}</option>
                    </NativeSelect>
                    {alertValidationError && alertValidationError.id === alert.id && (
                        <FormHelperText error>{res.DADO_INCORRETO}</FormHelperText>
                    )}
                </FormControl>
                <Button onClick={() => this.handleDeleteAlert(index)}>
                    <DeleteIcon />
                </Button>
            </div>
        ));
    }

    getAlertLevelForRow = (metrics) => {
        const alertPriority = {
            erro: 3,
            atencao: 2,
            normal: 1
        };

        let maxAlertLevel = 'normal';

        Object.values(metrics).forEach(metric => {
            if (alertPriority[metric.alertLevel] > alertPriority[maxAlertLevel]) {
                maxAlertLevel = metric.alertLevel;
            }
        });

        return maxAlertLevel;
    };

    renderAlertIcon = (alertLevel) => {
        switch (alertLevel) {
            case 'atencao':
                return <WarningIcon style={{ color: '#e7e700', marginLeft: '0.5rem' }} />;
            case 'erro':
                return <ErrorIcon style={{ color: 'red', marginLeft: '0.5rem' }} />;
            default:
                return null;
        }
    };

    renderDataCell = (dataPoint, value, classes, isExpanded, row) => {
        let alertLevel = 'normal';

        for (const alert of this.state.selectedAlerts) {
            if (alert.qualityData === dataPoint && value !== undefined && value >= alert.minimum && value <= alert.maximum) {
                alertLevel = alert.alertLevel.toLowerCase();
                break;
            }
        }

        return (
            <TableCell className={classes.tableCell} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4rem' }}>
                    {value !== '-' ? `${value}%` : value} {this.renderAlertIcon(alertLevel)}
                </div>
            </TableCell>
        );
    };

    calculateMetrics = (job) => {
        const cVelocidade = job.correctSpeed + job.acceptableSpeed / 2;
        const tVelocidade = job.correctSpeed + job.acceptableSpeed + job.unacceptableSpeed;
        const cAltura = job.correctHeight + job.acceptableHeight / 2;
        const tAltura = job.correctHeight + job.acceptableHeight + job.unacceptableHeight;
        const cFluxo = job.correctFlow + job.acceptableFlow / 2;
        const tFluxo = job.correctFlow + job.acceptableFlow + job.unacceptableFlow;
        const cTemperatura = job.correctTemperature + job.acceptableTemperature / 2;
        const tTemperatura = job.correctTemperature + job.acceptableTemperature + job.unacceptableTemperature;
        const cUmidade = job.correctHumidity + job.acceptableHumidity / 2;
        const tUmidade = job.correctHumidity + job.acceptableHumidity + job.unacceptableHumidity;
        const cVelocidadeV = job.correctWindSpeed + job.acceptableWindSpeed / 2;
        const tVelocidadeV = job.correctWindSpeed + job.acceptableWindSpeed + job.unacceptableWindSpeed;
        const cDeltaT = job.correctDeltaT + job.acceptableDeltaT / 2;
        const tDeltaT = job.correctDeltaT + job.acceptableDeltaT + job.unacceptableDeltaT;

        const gAcerto = Math.round(job.spray_in / job.spray * 100);
        const gUniformidade = Math.round((job.spray_in - job.spray_over) / job.area_polygon * 100)
        const gFalha = Math.round((job.area_polygon - (job.spray_in - job.spray_over)) / job.area_polygon * 100);
        const gPerda = Math.round((job.spray_over + (job.spray - job.spray_in)) / job.spray * 100);
        const gVelocidade = Math.round(cVelocidade / tVelocidade * 100);
        const gAltura = Math.round(cAltura / tAltura * 100);
        const gFluxo = Math.round(cFluxo / tFluxo * 100);
        const gTemperatura = Math.round(cTemperatura / tTemperatura * 100);
        const gUmidade = Math.round(cUmidade / tUmidade * 100);
        const gVelocidadeV = Math.round(cVelocidadeV / tVelocidadeV * 100);
        const gDeltaT = Math.round(cDeltaT / tDeltaT * 100);

        return {
            acerto: {
                value: gAcerto,
                alertLevel: this.getAlertLevel('acerto', gAcerto)
            },
            uniformidade: {
                value: gUniformidade,
                alertLevel: this.getAlertLevel('uniformidade', gUniformidade)
            },
            falha: {
                value: gFalha,
                alertLevel: this.getAlertLevel('falha', gFalha)
            },
            perda: {
                value: gPerda,
                alertLevel: this.getAlertLevel('perda', gPerda)
            },
            velocidade: {
                value: gVelocidade,
                alertLevel: this.getAlertLevel('velocidade', gVelocidade)
            },
            altura: {
                value: gAltura,
                alertLevel: this.getAlertLevel('altura', gAltura)
            },
            fluxo: {
                value: gFluxo,
                alertLevel: this.getAlertLevel('fluxo', gFluxo)
            },
            temperatura: {
                value: gTemperatura,
                alertLevel: this.getAlertLevel('temperatura', gTemperatura)
            },
            umidade: {
                value: gUmidade,
                alertLevel: this.getAlertLevel('umidade', gUmidade)
            },
            vento: {
                value: gVelocidadeV,
                alertLevel: this.getAlertLevel('vento', gVelocidadeV)
            },
            deltaT: {
                value: gDeltaT,
                alertLevel: this.getAlertLevel('deltaT', gDeltaT)
            },
        };
    }

    renderSliderInvalidValue = (label) => {
        return (
            <div style={{ marginBottom: '.5rem' }}>
                <div className="titleTextRel" style={{ marginBottom: '.6rem' }}>
                    {label}
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 2rem' }}>
                    <div style={{ width: '100%', position: 'relative' }}>
                        <div style={{ marginBottom: '.3rem', borderRadius: '25px', height: '.6rem', width: '100%', backgroundColor: 'lightgray', overflow: 'hidden', border: 'solid 1px #c9c9c9' }}>
                            <div style={{ zIndex: '1', height: '1rem', width: '100%', backgroundColor: 'lightgray' }}></div>
                        </div>
                        <div style={{ width: 'calc(100% - 18px)', position: 'relative' }}>
                            <div style={{ borderRadius: '25px', marginTop: '-1.2rem', marginLeft: '', zIndex: '2', width: '18px', height: '18px', backgroundColor: 'gray', position: 'absolute' }}></div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '.5rem', marginTop: '-0.3rem' }}>
                        {''}
                    </div>
                </div>
            </div>
        );
    }

    getAngleFromCoordinates = (lat1, lng1, lat2, lng2) => {
        const rad = Math.PI / 180;
        const dLon = (lng2 - lng1) * rad;
        const y = Math.sin(dLon) * Math.cos(lat2 * rad);
        const x = Math.cos(lat1 * rad) * Math.sin(lat2 * rad) - Math.sin(lat1 * rad) * Math.cos(lat2 * rad) * Math.cos(dLon);
        let brng = Math.atan2(y, x);
        brng = brng / rad;
        brng = (brng + 360) % 360;
        return brng;
    }

    getCardinalDirection = (angle) => {
        if (angle >= 348.75 || angle < 11.25) {
            return 'N';
        } else if (angle >= 11.25 && angle < 33.75) {
            return 'NNE';
        } else if (angle >= 33.75 && angle < 56.25) {
            return 'NE';
        } else if (angle >= 56.25 && angle < 78.75) {
            return 'ENE';
        } else if (angle >= 78.75 && angle < 101.25) {
            return 'L';
        } else if (angle >= 101.25 && angle < 123.75) {
            return 'ESE';
        } else if (angle >= 123.75 && angle < 146.25) {
            return 'SE';
        } else if (angle >= 146.25 && angle < 168.75) {
            return 'SSE';
        } else if (angle >= 168.75 && angle < 191.25) {
            return 'S';
        } else if (angle >= 191.25 && angle < 213.75) {
            return 'SSO';
        } else if (angle >= 213.75 && angle < 236.25) {
            return 'SO';
        } else if (angle >= 236.25 && angle < 258.75) {
            return 'OSO';
        } else if (angle >= 258.75 && angle < 281.25) {
            return 'O';
        } else if (angle >= 281.25 && angle < 303.75) {
            return 'ONO';
        } else if (angle >= 303.75 && angle < 326.25) {
            return 'NO';
        } else if (angle >= 326.25 && angle < 348.75) {
            return 'NNO';
        }
    }

    renderTableRow = (job, isExpanded) => {
        const { res, classes } = this.props;
        const metrics = this.calculateMetrics(job);
        const alertLevel = this.getAlertLevelForRow(metrics);
        const backgroundColors = {
            normal: 'white',
            atencao: 'rgb(255 255 0 / 19%)',
            erro: 'rgb(255 0 0 / 13%)'
        };
        const rowStyle = {
            backgroundColor: backgroundColors[alertLevel],
            borderBottom: isExpanded ? 'none' : '1px solid #ccc',
        };
        const formatTimestampToDateTime = (timestamp) => {
            var date = new Date(timestamp);
            return (date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()) + " "
                + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
        }
        const jobGuid = this.state.allJobs.find(j => j.id === job.id_job_link)?.guid;
        const areaNaoAplicada = Math.max(0, parseInt(job.area_polygon - job.spray, 10));
        const areaAplicada = Math.max(0, parseInt(job.spray - job.spray_over, 10));
        const sobreposto = Math.max(0, parseInt(job.spray_over, 10));
        const areaAplicadaInterna = Math.max(0, parseInt(job.spray_in - job.spray_over, 10));
        const areaAplicadaExterna = Math.max(0, parseInt(job.spray - job.spray_in, 10));

        if (isExpanded) {
            const polygons = job.areas ? job.areas.map(item => item.points) : [];
            const formatABs = (ABs) => {
                if (!ABs) return [];
                const points = JSON.parse(ABs);
                const angle = this.getAngleFromCoordinates(points[0][1], points[0][0], points[1][1], points[1][0]);
                const direction = this.getCardinalDirection(angle);

                return {
                    startPoint: { lat: points[0][1], lng: points[0][0] },
                    endPoint: { lat: points[1][1], lng: points[1][0] },
                    direction: direction
                };
            }
            const formattedABs = job.areas ? job.areas.map(item => formatABs(item.ABs)) : [];
            setTimeout(() => {
                this.drawPolygon(polygons, formattedABs, job.id);
            }, 100);
        }

        return (
            <>
                <TableRow className={classes.tableRow} key={job.id} style={rowStyle}>
                    <TableCell className={classes.tableCell} >{job.title ? job.title : '-'}</TableCell>
                    <TableCell className={classes.tableCell} >{job.client_name ? job.client_name : '-'}</TableCell>
                    <TableCell className={classes.tableCell}>{job.area_polygon ? `${new Intl.NumberFormat().format(job.area_polygon)} ha` : '-'}</TableCell>
                    <TableCell className={classes.tableCell} >{job.startDate ? formatTimestampToDateTime(job.startDate) : '-'}</TableCell>
                    <TableCell className={classes.tableCell} >{job.endDate ? formatTimestampToDateTime(job.endDate) : '-'}</TableCell>
                    {this.renderDataCell('acerto', !isNaN(metrics.acerto.value) && isFinite(metrics.acerto.value) ? metrics.acerto.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('uniformidade', !isNaN(metrics.uniformidade.value) && isFinite(metrics.uniformidade.value) ? metrics.uniformidade.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('falha', !isNaN(metrics.falha.value) && isFinite(metrics.falha.value) ? metrics.falha.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('perda', !isNaN(metrics.perda.value) && isFinite(metrics.perda.value) ? metrics.perda.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('velocidade', !isNaN(metrics.velocidade.value) && isFinite(metrics.velocidade.value) ? metrics.velocidade.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('altura', !isNaN(metrics.altura.value) && isFinite(metrics.altura.value) ? metrics.altura.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('fluxo', !isNaN(metrics.fluxo.value) && isFinite(metrics.fluxo.value) ? metrics.fluxo.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('temperatura', !isNaN(metrics.temperatura.value) && isFinite(metrics.temperatura.value) ? metrics.temperatura.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('umidade', !isNaN(metrics.umidade.value) && isFinite(metrics.umidade.value) ? metrics.umidade.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('vento', !isNaN(metrics.vento.value) && isFinite(metrics.vento.value) ? metrics.vento.value : '-', classes, isExpanded, job)}
                    {this.renderDataCell('deltaT', !isNaN(metrics.deltaT.value) && isFinite(metrics.deltaT.value) ? metrics.deltaT.value : '-', classes, isExpanded, job)}
                    <TableCell className={classes.tableCell} >
                        <FormControl>
                            <NativeSelect
                                style={{ marginTop: '0.5rem' }}
                                value={this.state.jobStatus[job.id_job_link] || ''}
                                onChange={(event) => this.handleStatus(event, job)}
                                inputProps={{ style: { textAlign: 'center' } }}
                            >
                                <option value="analise">Em análise</option>
                                <option value="aprovado">Aprovado</option>
                                <option value="rejeitado">Rejeitado</option>
                                <option value="retrabalho">Retrabalho</option>
                            </NativeSelect>
                        </FormControl>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button className="buttonS" style={{ minWidth: '2.5rem', backgroundColor: 'rgb(82, 171, 5)', height: '2.5rem', paddingLeft: 0 }} onClick={() => this.props.link(`qualidade/${jobGuid}`)}>
                                <svg xmlns="http://www.w3.org/2000/svg" color="white" width="2rem" height="2rem" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="-110 -50 400 400">
                                    <g id="_2539151417168">
                                        <path class="fil0" d="M133 17.44c58.05,0 106.23,43.9 115.57,101.56l-27.61 0c-8.97,-41.97 -44.93,-73.35 -87.96,-73.35 -49.77,0 -90.11,41.99 -90.11,93.79 0,25.33 9.64,48.3 25.32,65.18l-19.24 19.88c-20.54,-21.98 -33.19,-51.98 -33.19,-85.06 0,-67.38 52.48,-122 117.22,-122z" />
                                        <path class="fil0" d="M57.61 154.63l-42.68 0c-1.63,0 -2.97,-1.33 -2.97,-2.96l0 -24.46c0,-1.63 1.34,-2.96 2.97,-2.96l42.68 0c1.64,0 2.97,1.33 2.97,2.96l0 24.46c0,1.63 -1.33,2.96 -2.97,2.96z" />
                                        <path class="fil0" d="M67.46 95.53l-32.35 -26.76c-1.42,-1.16 -1.62,-3.26 -0.45,-4.67l15.13 -18.3c1.17,-1.41 3.27,-1.61 4.68,-0.45l32.35 26.76c1.41,1.16 1.61,3.26 0.45,4.67l-15.13 18.3c-1.17,1.41 -3.28,1.61 -4.68,0.45z" />
                                        <path class="fil0" d="M146.59 16.83l0 41.44c0,1.98 -1.62,3.59 -3.59,3.59l-23.21 0c-1.98,0 -3.59,-1.61 -3.59,-3.59l0 -41.44c0,-1.98 1.61,-3.59 3.59,-3.59l23.21 0c1.97,0 3.59,1.61 3.59,3.59z" />
                                        <g>
                                            <path class="fil0" d="M119.46 126.91l46.07 -48.13c5.44,-5.69 14.54,-5.89 20.22,-0.44l0.01 0c5.69,5.45 5.88,14.54 0.44,20.23l-46.08 48.13c-5.44,5.69 -14.53,5.88 -20.22,0.44l-0.01 0c-5.68,-5.45 -5.88,-14.54 -0.43,-20.23z" />
                                            <path class="fil0" d="M130.02 114.41c12.39,0 22.42,10.03 22.42,22.42 0,12.39 -10.03,22.43 -22.42,22.43 -12.39,0 -22.42,-10.04 -22.42,-22.43 0,-12.39 10.03,-22.42 22.42,-22.42z" />
                                        </g>
                                        <path class="fil0" d="M219.66 183.43l-19.48 -17.87 -42.87 42.86c-5.16,4.94 -9.72,4.52 -13.8,0l-25.63 -25.63c-6.35,-6.83 -12.86,-7.46 -19.58,0l-53.12 53.12c-5.03,8.19 12.45,23.9 20.39,15.83l35.82 -35.81c4.6,-4.75 9.19,-4.55 13.81,0l26.08 26.08c6.42,6.89 13.02,6.35 19.79,0l58.59 -58.58zm-8.47 -28.53l-21.82 -22.91 61.56 -0.4 0 62.24 -19.33 -21.41 -11.94 11.01 -19.48 -17.87 11.01 -10.66z" />
                                    </g>
                                </svg>
                            </Button>
                            <IconButton onClick={() => this.toggleRow(job.id)} style={{ padding: '0.5rem' }}>
                                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </div>
                    </TableCell>
                </TableRow>
                {isExpanded && (
                    <TableRow className={classes.expandedRowContent} style={{ backgroundColor: backgroundColors[alertLevel] }}>
                        <TableCell colSpan={18} style={{ padding: 0 }}>
                            <div className={classes.expandedContent}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className={classes.sliderContainer}>
                                        {!isNaN(metrics.acerto.value) && isFinite(metrics.acerto.value) ? <SliderComponent res={res} text={res.ACERTO_} value={metrics.acerto.value} /> : this.renderSliderInvalidValue(res.ACERTO_)}
                                        {!isNaN(metrics.uniformidade.value) && isFinite(metrics.uniformidade.value) ? <SliderComponent res={res} text={res.UNIFORMIDADE} value={metrics.uniformidade.value} /> : this.renderSliderInvalidValue(res.UNIFORMIDADE)}
                                        {!isNaN(metrics.falha.value) && isFinite(metrics.falha.value) ? <SliderComponent res={res} text={res.FALHA_} inverted={true} value={metrics.falha.value} /> : this.renderSliderInvalidValue(res.FALHA_)}
                                        {!isNaN(metrics.perda.value) && isFinite(metrics.perda.value) ? <SliderComponent res={res} text={res.PERDA} inverted={true} value={metrics.perda.value} /> : this.renderSliderInvalidValue(res.PERDA)}
                                    </div>
                                    <div className={classes.chartContainer}>
                                        <Charts
                                            chartType="areaTotal"
                                            dataLabels={['Área não aplicada', 'Área aplicada', 'Sobreposto']}
                                            dataValues={[areaNaoAplicada, areaAplicada, sobreposto]}
                                            dataUnits={['ha', 'ha', 'ha']}
                                            backgroundColors={['#fa7d34', 'rgb(111, 201, 32)', 'rgb(255 226 81)']}
                                            title={'1.25rem'}
                                            subtitle={'1rem'}
                                        />
                                    </div>
                                    <div className={classes.chartContainer}>
                                        <Charts
                                            chartType="doughnut"
                                            dataLabels={['Área aplicada externa', 'Área aplicada interna', 'Sobreposto']}
                                            dataValues={[areaAplicadaExterna, areaAplicadaInterna, sobreposto]}
                                            dataUnits={['ha', 'ha', 'ha']}
                                            backgroundColors={['#fa7d34', 'rgb(111, 201, 32)', 'rgb(255 226 81)']}
                                            title={'1.25rem'}
                                            subtitle={'1rem'}
                                        />
                                    </div>
                                    <div className={classes.sliderContainer} style={{ display: 'flex' }}>
                                        <div style={{ paddingRight: '0.375rem', width: '50%' }}>
                                            {!isNaN(metrics.velocidade.value) && isFinite(metrics.velocidade.value) ? <SliderComponent res={res} text={res.VELOCIDADE} value={metrics.velocidade.value} /> : this.renderSliderInvalidValue(res.VELOCIDADE)}
                                            {!isNaN(metrics.altura.value) && isFinite(metrics.altura.value) ? <SliderComponent res={res} text={res.ALTURA} value={metrics.altura.value} /> : this.renderSliderInvalidValue(res.ALTURA)}
                                            {!isNaN(metrics.fluxo.value) && isFinite(metrics.fluxo.value) ? <SliderComponent res={res} text={res.FLUXO} value={metrics.fluxo.value} /> : this.renderSliderInvalidValue(res.FLUXO)}
                                            {!isNaN(metrics.temperatura.value) && isFinite(metrics.temperatura.value) ? <SliderComponent res={res} text={res.TEMPERATURA_} value={metrics.temperatura.value} /> : this.renderSliderInvalidValue(res.TEMPERATURA_)}
                                        </div>
                                        <div style={{ paddingLeft: '0.375rem', width: '50%' }}>
                                            {!isNaN(metrics.umidade.value) && isFinite(metrics.umidade.value) ? <SliderComponent res={res} text={res.UMIDADE_} value={metrics.umidade.value} /> : this.renderSliderInvalidValue(res.UMIDADE_)}
                                            {!isNaN(metrics.vento.value) && isFinite(metrics.vento.value) ? <SliderComponent res={res} text={res.VENTO} value={metrics.vento.value} /> : this.renderSliderInvalidValue(res.VENTO)}
                                            {!isNaN(metrics.deltaT.value) && isFinite(metrics.deltaT.value) ? <SliderComponent res={res} text={res.DELTA_T} value={metrics.deltaT.value} /> : this.renderSliderInvalidValue(res.DELTA_T)}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', border: '1px solid darkgrey', height: '12rem', width: '100%', marginTop: '1rem', justifyContent: 'space-between', backgroundColor: 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '600', fontSize: '1.25rem', margin: '0.5rem' }}>N</span>
                                        <span style={{ fontWeight: '600', fontSize: '1.25rem', margin: '0.5rem' }}>S</span>
                                    </div>
                                    <div>
                                        <canvas id={`polygon-canvas-${job.id}`} style={{ width: '100%', height: '100%' }}></canvas>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    };

    handleStatus = (event, job) => {
        const newStatus = event.target.value;
        this.setState(prevState => ({
            jobStatus: {
                ...prevState.jobStatus,
                [job.id_job_link]: newStatus,
            },
        }), () => {
            if (newStatus === 'aprovado') {
                this.handleClickOpenConfirmation(job);
            } else {
                this.statusJobUpdate(job);
            }
        });
    };

    handleClickOpenConfirmation = (job) => {
        this.setState({ confirmationDialog: true, selectedWork: job });
    };

    handleCloseConfirmation = (confirmed) => {
        if (confirmed) {
            this.setState({ confirmationDialog: false });
            this.statusJobUpdate(this.state.selectedWork);
        } else {
            this.setState(prevState => ({
                confirmationDialog: false,
                jobStatus: {
                    ...prevState.jobStatus,
                    [prevState.selectedWork.id]: 'analise',
                },
                temporaryAlerts: [],
            }), () => {
                this.statusJobUpdate({ ...this.state.selectedWork, statusJob: 'analise' });
            });
        }
    };

    renderConfirmationDialog = () => {
        const { res } = this.props;
        const { selectedWork, confirmationDialog } = this.state;

        return (
            <Dialog open={confirmationDialog} onClose={() => this.handleCloseConfirmation(false)}>
                <DialogTitle id="alert-dialog-title" style={{ padding: '0' }}>
                    <div style={{ textAlign: 'center', padding: '0.5rem 0', backgroundColor: '#2D3238' }} >
                        <h3 style={{ margin: '0', color: 'white', whiteSpace: 'nowrap', }}>Confirmação de Aprovação</h3>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ margin: '0', padding: '1rem', color: 'black' }}>
                        <p style={{ margin: '0', padding: '0.25rem 0' }}>Você selecionou o status "Aprovado" para o trabalho {selectedWork?.nome}.</p>
                        <p style={{ margin: '0', padding: '0.25rem 0' }}>Confirme para validar que o trabalho atendeu às suas expectativas e está aprovado.</p>
                        <p style={{ margin: '0', padding: '0.25rem 0' }}>Cancele se deseja não aprovar o trabalho neste momento.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleCloseConfirmation(false)}>
                        {res.CANCELAR}
                    </Button>
                    <Button onClick={() => this.handleCloseConfirmation(true)}>
                        {res.CONFIRMAR}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    calculateAverageMetrics = () => {
        const filteredJobs = this.state.jobDetail.filter(job => {
            let jobStatus = job.statusJob === undefined ? 'analise' : job.statusJob;
            return this.state.filterValues.status.includes(jobStatus);
        });

        let sumMetrics = {
            acerto: 0, uniformidade: 0, falha: 0, perda: 0,
            velocidade: 0, altura: 0, fluxo: 0, temperatura: 0,
            umidade: 0, vento: 0, deltaT: 0
        };

        let countMetrics = {
            acerto: 0, uniformidade: 0, falha: 0, perda: 0,
            velocidade: 0, altura: 0, fluxo: 0, temperatura: 0,
            umidade: 0, vento: 0, deltaT: 0
        };

        filteredJobs.forEach(job => {
            let metrics = this.calculateMetrics(job);
            Object.keys(sumMetrics).forEach(key => {
                let metricValue = metrics[key] && metrics[key].value;
                if (!isNaN(metricValue) && isFinite(metricValue) && metricValue !== '-') {
                    sumMetrics[key] += metricValue;
                    countMetrics[key] += 1;
                }
            });
        });

        let averageMetrics = {};
        Object.keys(sumMetrics).forEach(key => {
            if (countMetrics[key] > 0) {
                averageMetrics[key] = sumMetrics[key] / countMetrics[key];
            } else {
                averageMetrics[key] = 0;
            }
        });

        return averageMetrics;
    }

    globalSlider = () => {
        const { res } = this.props;
        const globalMetrics = this.calculateAverageMetrics();

        const renderSliders = (key, label) => {
            const value = globalMetrics[key];
            if (!isNaN(value) && isFinite(value)) {
                if (value === 0) {
                    return this.renderSliderInvalidValue(label);
                } else if (value > 0) {
                    let formattedValue = value < 1 ? value.toFixed(1) : value.toFixed(0);
                    if (label === 'Falha' || label === 'Perda') {
                        return <SliderComponent inverted={true} style={{ width: '16rem' }} text={label} value={formattedValue} />;
                    } else {
                        return <SliderComponent style={{ width: '16rem' }} text={label} value={formattedValue} />;
                    }
                }
            } else {
                return this.renderSliderInvalidValue(label)
            }
        };

        return (
            <div style={{ display: 'flex', width: '50%' }}>
                <div style={{ width: '33%' }}>
                    {renderSliders('acerto', res.ACERTO_)}
                    {renderSliders('uniformidade', res.UNIFORMIDADE)}
                    {renderSliders('falha', res.FALHA_, true)}
                    {renderSliders('perda', res.PERDA, true)}
                </div>
                <div style={{ width: '33%', padding: '0 1rem' }}>
                    {renderSliders('velocidade', res.VELOCIDADE)}
                    {renderSliders('altura', res.ALTURA)}
                    {renderSliders('fluxo', res.FLUXO)}
                    {renderSliders('temperatura', res.TEMPERATURA_)}
                </div>
                <div style={{ width: '33%' }}>
                    {renderSliders('umidade', res.UMIDADE_)}
                    {renderSliders('vento', res.VENTO)}
                    {renderSliders('deltaT', res.DELTA_T)}
                </div>
            </div>
        )
    }

    statusJobUpdate = (data) => {
        let apiUrl = '';
        let method = '';
        apiUrl = Config.server + `/job_link/${data.id}/${this.state.jobStatus[data.id_job_link]}`;

        const formData = new URLSearchParams();
        if (data.id_job_link) {
            formData.append('id', data.id_job_link);
            method = 'PUT';
        }

        formData.append('statusJob', this.state.jobStatus[data.id_job_link]);

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    let msg = this.props.res.ITEM_ALTERADO;
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else {
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: msg,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                    }
                    this.readJobLink();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        this.props.link('/login');
                    }
                    else {
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG_INFORMACOES,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    }

    readAlerts = () => {
        const apiUrl = Config.server + `/job_settings`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                const initialAlerts = result[0] && result[0].alerts ? JSON.parse(result[0].alerts) : [];

                this.setState({
                    allAlerts: result[0],
                    selectedAlerts: initialAlerts,
                });
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    validateAlerts = (alerts) => {
        const alertTypes = {};
        const typeTranslations = {
            'acerto': 'Acerto',
            'uniformidade': 'Uniformidade',
            'falha': 'Falha',
            'perda': 'Perda',
            'velocidade': 'Velocidade',
            'altura': 'Altura',
            'fluxo': 'Fluxo',
            'temperatura': 'Temperatura',
            'umidade': 'Umidade',
            'vento': 'Vento',
            'deltaT': 'Delta-T'
        };
        const levelTranslations = {
            'atencao': 'Atenção',
            'erro': 'Problema'
        };

        for (const alert of alerts) {
            const type = typeTranslations[alert.qualityData] || alert.qualityData;
            const level = levelTranslations[alert.alertLevel] || alert.alertLevel;
            const min = parseInt(alert.minimum, 10);
            const max = parseInt(alert.maximum, 10);

            if (alertTypes[alert.qualityData] && alertTypes[alert.qualityData][alert.alertLevel]) {
                return {
                    id: alert.id,
                    message: `Já existe um alerta do nível '${level}' para o dado de qualidade do tipo '${type}'`
                };
            }

            if (!alertTypes[alert.qualityData]) {
                alertTypes[alert.qualityData] = {};
            }

            alertTypes[alert.qualityData][alert.alertLevel] = { min, max };

            for (const otherLevel in alertTypes[alert.qualityData]) {
                if (otherLevel !== alert.alertLevel) {
                    const otherAlert = alertTypes[alert.qualityData][otherLevel];
                    if ((min <= otherAlert.max && max >= otherAlert.min)) {
                        return {
                            id: alert.id,
                            message: `Conflito de intervalos entre os alertas de nível '${level}' e '${levelTranslations[otherLevel] || otherLevel}' para o dado de qualidade do tipo '${type}'`
                        };
                    }
                }
            }
        }

        return null;
    };

    registerAlerts = (event) => {
        const { res } = this.props
        event.preventDefault();
        let apiUrl = '';
        let method = '';
        apiUrl = Config.server + '/job_settings';

        const formData = new URLSearchParams();

        if (this.state.allAlerts?.id) {
            apiUrl += '/' + this.state.allAlerts.id;
            formData.append('id', this.state.allAlerts.id);
            method = 'PUT';
        } else {
            method = 'POST';
        }

        const sortedAlerts = this.sortAlerts(this.state.temporaryAlerts);

        const validationError = this.validateAlerts(sortedAlerts);
        if (validationError) {
            this.setState({ alertValidationError: validationError });
            notification.open({
                message: res.ERRO,
                description: validationError.message,
                icon: <MaterialIcon icon="error" className="text-danger" />
            });
            return;
        } else {
            this.setState({ alertValidationError: null });
        }

        formData.append('alerts', JSON.stringify(sortedAlerts));

        let options = {
            method: method,
            body: formData,
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then(
                (result) => {
                    if (result && result.error) {
                        notification.open({
                            message: this.props.res[result.message],
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else {
                        notification.open({
                            message: this.props.res.SUCESSO,
                            description: this.props.res.CONFIGURACAO_ALERTAS,
                            icon: <MaterialIcon icon="check_circle" className="text-success" />
                        });
                    }

                    this.setState(prevState => ({
                        selectedAlerts: [...prevState.temporaryAlerts],
                        configurationDialog: false,
                    }));
                    this.calculateAlerts();
                },
                (error) => {
                    if (error.status === 405) {
                        notification.open({
                            message: this.props.res.PERMISSAO,
                            description: this.props.res.PERMISSAO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    } else if (error.status === 401 || error.status === 403) {
                        console.log(error);
                        this.props.link('/login');
                    }
                    else {
                        console.log(error);
                        notification.open({
                            message: this.props.res.ERRO,
                            description: this.props.res.ERRO_MSG,
                            icon: <MaterialIcon icon="error" className="text-danger" />
                        });
                    }
                }
            );
    }

    handleFilterChange = (field, value) => {
        this.setState(prevState => ({
            filterValues: {
                ...prevState.filterValues,
                [field]: value
            }
        }));
    };

    isValidDate = (dateString) => {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        return dateString.match(regEx) != null;
    }

    applyFilters = () => {
        const { allJobDetail, filterValues } = this.state;

        const filteredItems = allJobDetail.filter(job => {
            const metrics = this.calculateMetrics(job);
            const getMetricValue = (data) => !isNaN(data) && isFinite(data) ? data : 0;

            const titleMatch = !filterValues.nome || (job.title && job.title.toLowerCase().includes(filterValues.nome.toLowerCase()));
            const startDateMatch = !filterValues.dataInicio || (this.isValidDate(filterValues.dataInicio) && new Date(job.startDate) >= new Date(filterValues.dataInicio));
            const endDateMatch = !filterValues.dataFim || (this.isValidDate(filterValues.dataFim) && new Date(job.endDate) <= new Date(filterValues.dataFim));
            const statusMatch = !filterValues.status || filterValues.status.length === 0 || filterValues.status.includes(job.statusJob === undefined ? 'analise' : job.statusJob);
            const acertoMatch = !filterValues.acertoRange || (getMetricValue(metrics.acerto.value) >= filterValues.acertoRange[0] && getMetricValue(metrics.acerto.value) <= filterValues.acertoRange[1]);
            const uniformidadeMatch = !filterValues.uniformidadeRange || (getMetricValue(metrics.uniformidade.value) >= filterValues.uniformidadeRange[0] && getMetricValue(metrics.uniformidade.value) <= filterValues.uniformidadeRange[1]);
            const falhaMatch = !filterValues.falhaRange || (getMetricValue(metrics.falha.value) >= filterValues.falhaRange[0] && getMetricValue(metrics.falha.value) <= filterValues.falhaRange[1]);
            const perdaMatch = !filterValues.perdaRange || (getMetricValue(metrics.perda.value) >= filterValues.perdaRange[0] && getMetricValue(metrics.perda.value) <= filterValues.perdaRange[1]);
            const velocidadeMatch = !filterValues.velocidadeRange || (getMetricValue(metrics.velocidade.value) >= filterValues.velocidadeRange[0] && getMetricValue(metrics.velocidade.value) <= filterValues.velocidadeRange[1]);
            const alturaMatch = !filterValues.alturaRange || (getMetricValue(metrics.altura.value) >= filterValues.alturaRange[0] && getMetricValue(metrics.altura.value) <= filterValues.alturaRange[1]);
            const fluxoMatch = !filterValues.fluxoRange || (getMetricValue(metrics.fluxo.value) >= filterValues.fluxoRange[0] && getMetricValue(metrics.fluxo.value) <= filterValues.fluxoRange[1]);
            const temperaturaMatch = !filterValues.temperaturaRange || (getMetricValue(metrics.temperatura.value) >= filterValues.temperaturaRange[0] && getMetricValue(metrics.temperatura.value) <= filterValues.temperaturaRange[1]);
            const umidadeMatch = !filterValues.umidadeRange || (getMetricValue(metrics.umidade.value) >= filterValues.umidadeRange[0] && getMetricValue(metrics.umidade.value) <= filterValues.umidadeRange[1]);
            const ventoMatch = !filterValues.ventoRange || (getMetricValue(metrics.vento.value) >= filterValues.ventoRange[0] && getMetricValue(metrics.vento.value) <= filterValues.ventoRange[1]);
            const deltaTMatch = !filterValues.deltaTRange || (getMetricValue(metrics.deltaT.value) >= filterValues.deltaTRange[0] && getMetricValue(metrics.deltaT.value) <= filterValues.deltaTRange[1]);

            return titleMatch && startDateMatch && endDateMatch && statusMatch && acertoMatch && uniformidadeMatch && falhaMatch && perdaMatch && velocidadeMatch && alturaMatch && fluxoMatch && temperaturaMatch && umidadeMatch && ventoMatch && deltaTMatch;
        });

        const newStatusCounts = this.calculateJobStatusCounts(filteredItems);
        const totalSpray = filteredItems.reduce((sum, job) => sum + (job.spray || 0), 0);
        const totalAreaPolygon = filteredItems.reduce((sum, job) => sum + (job.area_polygon || 0), 0);

        this.setState({
            totalSpray: totalSpray,
            totalAreaPolygon: totalAreaPolygon,
            jobDetail: filteredItems,
            ...newStatusCounts
        }, () => {
            this.calculateAlerts();
            this.saveFilters();
            this.handleCloseFilter();

            const prevStartDate = this.state.filteredStartDate;
            const initialStartDate = new Date(filterValues.dataInicio);

            if (initialStartDate.getDate() != new Date(filterValues.dataInicio).getDate()) {
                initialStartDate.setDate(0);
            }
            const startDate = initialStartDate.toISOString().split('T')[0];

            if (startDate < prevStartDate) {
                this.setState({ filteredStartDate: startDate }, () => {
                    this.readJobs(startDate);
                });
            }
        });
    };

    calculateJobStatusCounts = (jobs) => {
        let countJobAprovado = 0;
        let countJobEmAnalise = 0;
        let countJobRetrabalho = 0;
        let countJobRejeitado = 0;

        jobs.forEach(job => {
            if (job.statusJob === 'aprovado') {
                countJobAprovado++;
            } else if (job.statusJob === 'retrabalho') {
                countJobRetrabalho++;
            } else if (job.statusJob === 'rejeitado') {
                countJobRejeitado++;
            } else {
                countJobEmAnalise++;
            }
        });

        return { countJobAprovado, countJobEmAnalise, countJobRetrabalho, countJobRejeitado };
    };

    clearFilters = () => {
        const updatedJobDetails = this.state.allJobDetail.filter(detail =>
            this.state.allJobs.some(job => detail.id_job_link === job.id)
        );

        const currentDate = new Date();
        const previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 12, currentDate.getDate());

        if (previousDate.getDate() != currentDate.getDate()) {
            previousDate.setDate(0);
        }

        const dataFim = currentDate.toISOString().split('T')[0];
        const dataInicio = previousDate.toISOString().split('T')[0];

        this.setState({
            filterValues: {
                nome: '',
                dataInicio: dataInicio,
                dataFim: dataFim,
                status: ['aprovado', 'retrabalho', 'analise', 'rejeitado'],
                acertoRange: [0, 100],
                uniformidadeRange: [0, 100],
                falhaRange: [0, 100],
                perdaRange: [0, 100],
                velocidadeRange: [0, 100],
                alturaRange: [0, 100],
                fluxoRange: [0, 100],
                temperaturaRange: [0, 100],
                umidadeRange: [0, 100],
                ventoRange: [0, 100],
                deltaTRange: [0, 100],
            },
            jobDetail: updatedJobDetails
        }, () => {
            const { countJobAprovado, countJobEmAnalise, countJobRetrabalho, countJobRejeitado } = this.calculateJobStatusCounts(updatedJobDetails);
            this.setState({
                countJobAprovado,
                countJobEmAnalise,
                countJobRetrabalho,
                countJobRejeitado
            });
            this.calculateAlerts();
            this.saveFilters();
            this.readJobs(dataInicio);
        });
    };

    countJobsByStatus(filteredJobs) {
        let statusCounts = {
            aprovado: 0,
            analise: 0,
            rejeitado: 0,
            retrabalho: 0
        };

        filteredJobs.forEach(job => {
            if (job.statusJob === 'aprovado') {
                statusCounts.aprovado += 1;
            } else if (job.statusJob === 'rejeitado') {
                statusCounts.rejeitado += 1;
            } else if (job.statusJob === 'retrabalho') {
                statusCounts.retrabalho += 1;
            } else {
                statusCounts.analise += 1;
            }
        });

        return statusCounts;
    }

    saveFilters = () => {
        const { filterValues } = this.state;
        localStorage.setItem('dashboardFilters', JSON.stringify(filterValues));
    };

    previousFilters = (jobs) => {
        const savedFilters = localStorage.getItem('dashboardFilters');
        if (savedFilters) {
            const filterValues = JSON.parse(savedFilters);
            const currentDate = new Date();
            const sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 12, currentDate.getDate());
            if (sixMonthsAgo.getDate() !== currentDate.getDate()) {
                sixMonthsAgo.setDate(0);
            }
            const dataFimDefault = currentDate.toISOString().split('T')[0];
            const dataInicioDefault = sixMonthsAgo.toISOString().split('T')[0];

            filterValues.dataInicio = filterValues.dataInicio || dataInicioDefault;
            filterValues.dataFim = filterValues.dataFim || dataFimDefault;

            if (filterValues.status) {
                this.updateStatusFilter(jobs, filterValues.status);
            }

            if (filterValues.nome) {
                this.updateNameFilter(jobs, filterValues.nome);
            }

            if (filterValues.dataInicio || filterValues.dataFim) {
                this.updateDateFilter(jobs, filterValues.dataInicio, filterValues.dataFim);
            }

            const rangeFilterFields = [
                'acertoRange', 'uniformidadeRange', 'falhaRange', 'perdaRange',
                'velocidadeRange', 'alturaRange', 'fluxoRange', 'temperaturaRange',
                'umidadeRange', 'ventoRange', 'deltaTRange',
            ];
            rangeFilterFields.forEach(field => {
                if (filterValues[field]) {
                    this.updateRangeFilter(jobs, filterValues[field], field);
                }
            });

            this.setState({
                filterValues: filterValues,
            });
        }
    };

    updateNameFilter = (jobs, name) => {
        const filteredJobs = jobs.filter(job => {
            return job.title && job.title.trim().toLowerCase().includes(name.trim().toLowerCase());
        });
        const newStatusCounts = this.calculateJobStatusCounts(filteredJobs);

        this.setState({
            filterValues: {
                ...this.state.filterValues,
                nome: name,
            },
            ...newStatusCounts
        });
    };

    updateStatusFilter = (jobs, newStatus) => {
        const filteredJobs = jobs.filter(job => {
            const jobStatus = job.statusJob ? job.statusJob.toLowerCase() : 'analise';
            return newStatus.map(status => status.toLowerCase()).includes(jobStatus);
        });
        const newStatusCounts = this.calculateJobStatusCounts(filteredJobs);

        this.setState({
            filterValues: {
                ...this.state.filterValues,
                status: newStatus,
            },
            ...newStatusCounts
        });
    };

    updateRangeFilter = (jobs, range, field) => {
        const metricField = field.replace('Range', '');
        const filteredJobs = jobs.filter(job => {
            const metrics = this.calculateMetrics(job);
            const metric = metrics[metricField];
            let value = metric && !isNaN(metric.value) ? metric.value : null;

            if (range[0] === 0 && range[1] === 100) {
                return value === null || value === Infinity || (value >= range[0] && value <= range[1]);
            }

            return value !== null && value >= range[0] && value <= range[1];
        });
        const newStatusCounts = this.calculateJobStatusCounts(filteredJobs);

        this.setState({
            filterValues: {
                ...this.state.filterValues,
                [field]: range,
            },
            filteredJobs: filteredJobs,
            ...newStatusCounts
        });
    };

    updateDateFilter = (jobs, dataInicio, dataFim) => {
        const startDate = dataInicio;
        const endDate = dataFim;
        const formatTimestampToDateTime = (timestamp) => {
            var date = new Date(timestamp);
            return (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
        }
        const filteredJobs = jobs.filter(job => {
            const jobStartDate = formatTimestampToDateTime(job.startDate);
            const jobEndDate = formatTimestampToDateTime(job.endDate);
            return jobStartDate >= startDate && jobEndDate <= endDate;
        });
        const newStatusCounts = this.calculateJobStatusCounts(filteredJobs);

        this.setState({
            filterValues: {
                ...this.state.filterValues,
                dataInicio: dataInicio,
                dataFim: dataFim,
            },
            ...newStatusCounts
        });
    };

    handleStatusFilter = (event) => {
        const { value } = event.target;
        const newStatus = typeof value === 'string' ? value.split(',') : value;

        this.updateStatusFilter(this.state.allJobDetail, newStatus);
    };

    calculateAlerts = () => {
        let alertCounts = {
            erro: 0,
            atencao: 0,
            normal: 0,
            invalido: 0,
        };

        this.state.jobDetail.forEach(job => {
            const metrics = this.calculateMetrics(job);
            this.countAlerts(metrics, alertCounts);
        });

        this.setState({ alertCounts });
    };

    countAlerts = (metrics, alertCounts) => {
        Object.keys(metrics).forEach(key => {
            const metric = metrics[key];
            if (metric && metric.alertLevel) {
                switch (metric.alertLevel) {
                    case 'erro':
                        alertCounts.erro++;
                        break;
                    case 'atencao':
                        alertCounts.atencao++;
                        break;
                    case 'normal':
                        alertCounts.normal++;
                        break;
                    default:
                        alertCounts.invalido++;
                        break;
                }
            }
        });
    };

    clearFilter = (filterName) => {
        this.setState(prevState => ({
            filterValues: {
                ...prevState.filterValues,
                [filterName]: ''
            }
        }));
    };

    drawPolygon = (polygon, formattedABs, jobID) => {
        const canvas = document.getElementById(`polygon-canvas-${jobID}`);
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!polygon || polygon.length === 0) return;

        const polygons = polygon.map(p => JSON.parse(p));

        const width = canvas.width;
        const height = canvas.height;

        const greenFlag = new Image();
        greenFlag.src = img7;

        const redFlag = new Image();
        redFlag.src = img8;

        greenFlag.onload = () => {
            redFlag.onload = () => {
                let globalMinLat = Infinity, globalMaxLat = -Infinity, globalMinLon = Infinity, globalMaxLon = -Infinity;
                polygons.forEach(coords => {
                    coords.forEach(coord => {
                        globalMinLat = Math.min(globalMinLat, coord[0]);
                        globalMaxLat = Math.max(globalMaxLat, coord[0]);
                        globalMinLon = Math.min(globalMinLon, coord[1]);
                        globalMaxLon = Math.max(globalMaxLon, coord[1]);
                    });
                });

                const globalWidth = globalMaxLon - globalMinLon;
                const globalHeight = globalMaxLat - globalMinLat;

                const scaleX = (width - 20) / globalWidth;
                const scaleY = (height - 20) / globalHeight;
                const scale = Math.min(scaleX, scaleY);

                const offsetX = (width - globalWidth * scale) / 2;
                const offsetY = (height - globalHeight * scale) / 2;

                polygons.forEach((coords, index) => {
                    ctx.beginPath();
                    coords.forEach((coord, idx) => {
                        const x = (coord[1] - globalMinLon) * scale + offsetX;
                        const y = height - ((coord[0] - globalMinLat) * scale + offsetY);
                        if (idx === 0) {
                            ctx.moveTo(x, y);
                        } else {
                            ctx.lineTo(x, y);
                        }
                    });
                    ctx.closePath();
                    ctx.fillStyle = 'lightgray';
                    ctx.fill();
                    ctx.strokeStyle = 'black';
                    ctx.stroke();

                    if (formattedABs && formattedABs[index]) {
                        const area = formattedABs[index];
                        if (area.startPoint && area.endPoint) {
                            const startX = (area.startPoint.lng - globalMinLon) * scale + offsetX;
                            const startY = height - ((area.startPoint.lat - globalMinLat) * scale + offsetY);
                            const endX = (area.endPoint.lng - globalMinLon) * scale + offsetX;
                            const endY = height - ((area.endPoint.lat - globalMinLat) * scale + offsetY);

                            ctx.beginPath();
                            ctx.moveTo(startX, startY);
                            ctx.lineTo(endX, endY);
                            ctx.strokeStyle = 'orangered';
                            ctx.stroke();

                            ctx.drawImage(greenFlag, startX - 10, startY - 10, 20, 20);
                            ctx.drawImage(redFlag, endX - 10, endY - 10, 20, 20);
                        }
                    }
                });
            };
        };
    };

    render() {
        const { classes, res } = this.props;

        const statusLabels = {
            aprovado: 'Aprovado',
            rejeitado: 'Rejeitado',
            analise: 'Em Análise',
            retrabalho: 'Retrabalho'
        };

        const sortedJobDetails = [...this.state.jobDetail].sort((a, b) => {
            const dateA = a.startDate ? new Date(a.startDate) : new Date(0);
            const dateB = b.startDate ? new Date(b.startDate) : new Date(0);
            return dateB - dateA;
        });

        return (
            <div style={{ display: 'relative', position: 'relative' }}>
                {this.state.isLoading ?
                    <div style={{ padding: "0.5rem" }}>
                        <div
                            style={{
                                marginBottom: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Skeleton variant="h1" width={240} height={36} />
                            <div style={{ display: "flex" }}>
                                <Skeleton
                                    style={{ margin: "0 0.25rem" }}
                                    variant="h1"
                                    width={48}
                                    height={36}
                                />
                                <Skeleton
                                    style={{ margin: "0 0.25rem" }}
                                    variant="h1"
                                    width={48}
                                    height={36}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {/* SLIDER */}
                            <div style={{ display: "flex" }}>
                                <div>
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                </div>
                                <div style={{ margin: "0 1rem" }}>
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                </div>
                                <div>
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                    <Skeleton
                                        style={{ margin: "0.5rem 0" }}
                                        variant="text"
                                        width={240}
                                        height={40}
                                    />
                                </div>
                            </div>

                            <Skeleton
                                style={{ margin: "0 0.25rem", borderRadius: '0.5rem' }}
                                variant="h1"
                                width={160}
                                height={200}
                            />

                            {/* GRÁFICO */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="circle" width={200} height={200} />
                                <div style={{ marginLeft: "0.5rem" }}>
                                    <Skeleton variant="text" width={140} height={20} />
                                    <Skeleton variant="text" width={140} height={20} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="circle" width={200} height={200} />
                                <div style={{ marginLeft: "0.5rem" }}>
                                    <Skeleton variant="text" width={140} height={20} />
                                    <Skeleton variant="text" width={140} height={20} />
                                    <Skeleton variant="text" width={140} height={20} />
                                    <Skeleton variant="text" width={140} height={20} />
                                </div>
                            </div>
                        </div>

                        {/* TABELA */}
                        <Skeleton variant="rect" height={476} style={{ marginTop: "1rem" }} />
                    </div>
                    :
                    <div>
                        <div className="containerTop">
                            <div><h1>{res.DASHBOARD_QUALIDADE}</h1></div>
                            <div className="centerTop"></div>
                            <div>
                                <Button variant="contained" color="primary" style={{ marginRight: '0.4rem', paddingTop: '0.7rem', paddingBottom: '0.5rem' }} className="popUpButton" title={res.FILTRAR}
                                    onClick={() => { this.handleClickOpenFilter() }}>
                                    <FilterListIcon className="iconMapS" />
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginRight: '0.4rem', paddingTop: '0.7rem', paddingBottom: '0.5rem' }} className="popUpButton" title={res.ALERTAS}
                                    onClick={() => { this.handleClickOpen() }}>
                                    <NotificationImportantIcon className="iconMapS" />
                                </Button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            {this.globalSlider()}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '0 2rem',
                                borderRight: '0.125rem solid #ccc',
                                borderLeft: '0.125rem solid #ccc',
                                padding: '1rem 2rem',
                            }}>
                                <div style={{ fontWeight: 'bold', fontSize: '1.25rem', marginBottom: '0.5rem' }}>{res.AREA_TOTAL}</div>
                                <div style={{ fontSize: '1.5rem', fontWeight: '700', color: '#333' }}>
                                    <span>{new Intl.NumberFormat().format(this.state.totalAreaPolygon)} ha</span>
                                </div>
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.25rem',
                                    marginBottom: '0.5rem',
                                    marginTop: '1rem',
                                    whiteSpace: 'nowrap'
                                }}>
                                    {res.AREA_APLICADA_HA}
                                </div>
                                <div style={{ fontSize: '1.5rem', fontWeight: '700', color: '#333' }}>
                                    <span>{new Intl.NumberFormat().format(this.state.totalSpray)} ha</span>
                                </div>
                            </div>

                            <div className={classes.chartDoughnutContainer}>
                                <Charts
                                    chartType="doughnut"
                                    dataLabels={['Alerta de atenção', 'Alerta de problema']}
                                    dataValues={[this.state.alertCounts.atencao, this.state.alertCounts.erro]}
                                    dataUnits={['', '']}
                                    backgroundColors={['rgb(255 226 81)', 'rgb(255 0 0 / 80%)']}
                                    title={'2rem'}
                                    subtitle={'1.25rem'}
                                />
                            </div>
                            <div className={classes.chartDoughnutContainer}>
                                <Charts
                                    chartType="doughnut"
                                    dataLabels={['Aprovado', 'Em análise', 'Rejeitado', 'Retrabalho']}
                                    dataValues={[this.state.countJobAprovado, this.state.countJobEmAnalise, this.state.countJobRejeitado, this.state.countJobRetrabalho]}
                                    dataUnits={['', '', '', '']}
                                    backgroundColors={['rgb(111, 201, 32)', 'rgb(255 226 81)', 'rgb(255 0 0 / 80%)', '#fa7d34']}
                                    title={'2rem'}
                                    subtitle={'1.25rem'}
                                    parametro={'1rem'}
                                />
                            </div>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <div style={{ maxHeight: 'calc(100vh - 28rem)', overflowY: 'auto' }}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table" style={{ width: '100%', marginTop: 0, overflowY: 'auto' }} >
                                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                        <TableRow style={{ marginBottom: '0.5rem' }}>
                                            <TableCell style={{ width: '6%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.TITULO}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.PROPRIEDADE}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.AREA}</TableCell>
                                            <TableCell style={{ width: '6%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.DATA_INICIO}</TableCell>
                                            <TableCell style={{ width: '6%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.DATA_FIM}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.ACERTO_}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.UNIFORMIDADE}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.FALHA_}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.PERDA}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.VELOCIDADE}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.ALTURA}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.FLUXO}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.TEMPERATURA_}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.UMIDADE_}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.VENTO}</TableCell>
                                            <TableCell style={{ width: '5.5%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.DELTA_T}</TableCell>
                                            <TableCell style={{ width: '10%', textAlign: 'center', padding: '1rem 0.25rem' }}>{res.STATUS}</TableCell>
                                            <TableCell style={{ width: '0.5%', textAlign: 'center', padding: '1rem 0.25rem' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {sortedJobDetails.map((job) => {
                                        const isExpanded = this.state.expandedRows.includes(job.id);
                                        return (
                                            <TableBody>
                                                <React.Fragment key={job.id}>
                                                    {this.renderTableRow(job, isExpanded)}
                                                </React.Fragment>
                                            </TableBody>
                                        );
                                    })}
                                </Table>
                            </div>

                            <Dialog
                                open={this.state.filterDialog}
                                onClose={this.handleCloseFilter}
                                PaperProps={{ style: { maxWidth: '40rem', width: '100%' } }}
                            >
                                <DialogTitle id="alert-dialog-title" style={{ padding: '0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: ' 0.5rem 0', backgroundColor: '#2D3238' }} >
                                        <div></div>
                                        <div>
                                            <h3 style={{ margin: '0', color: 'white', whiteSpace: 'nowrap', }}>{res.FILTRAR}</h3>
                                        </div>
                                        <div>
                                            <CloseIcon
                                                onClick={this.handleCloseFilter}
                                                style={{ cursor: 'pointer', width: '2rem', height: '2rem', color: 'white', marginRight: '0.5rem' }}
                                                autoFocus
                                            />
                                        </div>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <FormControl style={{ width: '100%', marginBottom: '1rem' }}>
                                                <TextField
                                                    id="nome"
                                                    label={res.TITULO}
                                                    onChange={(e) => this.handleFilterChange('nome', e.target.value)}
                                                    value={this.state.filterValues.nome}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            this.state.filterValues.nome && (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => this.clearFilter('nome')}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                            <div style={{ marginBottom: '1rem' }}>
                                                <FormControl style={{ marginRight: '2rem', textAlign: 'center' }}>
                                                    <TextField
                                                        label='Data início'
                                                        style={{ width: '12.5rem' }}
                                                        id="dataInicio"
                                                        type="date"
                                                        value={this.state.filterValues.dataInicio}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        variant="standard"
                                                        onChange={(e) => this.handleFilterChange('dataInicio', e.target.value)}
                                                    />
                                                </FormControl>
                                                <FormControl style={{ textAlign: 'center', marginBottom: '1rem' }}>
                                                    <TextField
                                                        label='Data fim'
                                                        style={{ width: '12.5rem' }}
                                                        id="dataFim"
                                                        type="date"
                                                        value={this.state.filterValues.dataFim}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        variant="standard"
                                                        onChange={(e) => this.handleFilterChange('dataFim', e.target.value)}
                                                    />
                                                </FormControl>
                                            </div>
                                            <FormControl className={classes.formControl} style={{ marginBottom: '1rem' }}>
                                                <InputLabel id="labelStatus">{res.STATUS}</InputLabel>
                                                <Select
                                                    id="selectStatus"
                                                    multiple
                                                    value={this.state.filterValues.status}
                                                    onChange={this.handleStatusFilter}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.map(val => statusLabels[val]).join(', ')}
                                                >
                                                    {Object.entries(statusLabels).map(([value, label]) => (
                                                        <MenuItem key={value} value={value}>
                                                            <Checkbox checked={this.state.filterValues.status.includes(value)} />
                                                            <ListItemText primary={label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.ACERTO_}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.acertoRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('acertoRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.UNIFORMIDADE}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.uniformidadeRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('uniformidadeRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.FALHA_}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.falhaRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('falhaRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.PERDA}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.perdaRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('perdaRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.VELOCIDADE}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.velocidadeRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('velocidadeRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.ALTURA}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.alturaRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('alturaRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.FLUXO}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.fluxoRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('fluxoRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.TEMPERATURA_}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.temperaturaRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('temperaturaRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.UMIDADE_}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.umidadeRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('umidadeRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.VENTO}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.ventoRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('ventoRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Typography id="range-slider" gutterBottom>
                                                        {res.DELTA_T}
                                                    </Typography>
                                                    <Slider
                                                        value={this.state.filterValues.deltaTRange}
                                                        onChange={(e, newValue) => this.handleFilterChange('deltaTRange', newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        min={0}
                                                        max={100}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="primary" onClick={this.clearFilters}>
                                        {res.LIMPAR_FILTRO}
                                    </Button>
                                    <Button color="primary" onClick={this.applyFilters}>
                                        {res.FILTRAR}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={this.state.configurationDialog}
                                onClose={this.handleClose}
                                PaperProps={{ style: { maxWidth: '40rem', width: '100%' } }}
                            >
                                <DialogTitle id="alert-dialog-title" style={{ padding: '0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: ' 0.5rem 0', backgroundColor: '#2D3238' }} >
                                        <div></div>
                                        <div>
                                            <h3 style={{ margin: '0', color: 'white', whiteSpace: 'nowrap', }}>{res.CONFIGURACOES_ALERTAS}</h3>
                                        </div>
                                        <div>
                                            <CloseIcon
                                                onClick={this.handleClose}
                                                style={{ cursor: 'pointer', width: '2rem', height: '2rem', color: 'white', marginRight: '0.5rem' }}
                                                autoFocus
                                            />
                                        </div>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <div>
                                            <Button variant="contained" color="primary" title={res.ADICIONAR_ALERTA} onClick={this.handleAddAlert}>
                                                < AddAlertIcon />
                                            </Button>
                                            {this.renderAlertsInputs()}
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="primary" autoFocus onClick={this.registerAlerts}>
                                        {res.SALVAR}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {this.renderConfirmationDialog()}
                        </div >
                    </div >
                }
            </div >
        );
    }
}

QualityDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(QualityDashboard);

const Box = (props) => {
    const { link, res } = props;
    return (
        <div className="rowForm">
            <div className="divContent">
                <TextFields1 link={link} res={res} />
            </div>
        </div>)
}

export default Box;