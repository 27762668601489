import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';
import TextField from '@material-ui/core/TextField';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";

import L from 'leaflet';
import 'leaflet-draw';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import SvgIcon from '@material-ui/core/SvgIcon';
import tools from '../../components/util/tools';
import Slider from '../../components/Slider/slider';

import { useParams } from "react-router";

import Backd from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Arrow from './arrow';
import saveAs from 'file-saver';

import Img_mark from './../../components/image/img';
import img from './../point/img';

class marks {
  constructor() {
    this.id = '';
    this.guid = '';
    this.name = '';
    this.icon = '';
    this.alt = '';
    this.lat = '';
    this.lng = '';
    this.r = '';
    this.type = '';
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  table: {
    minWidth: 650,
  },
});

class Edit extends React.Component {
  base_state = {
    markers: [],

    title: '...',
    title_sk: '...',
    aeronavePrefixO: '',
    aeronavePrefixT: '',
    name: '',
    customerNameO: '',
    customerNameT: '',
    descricao: '',
    cost: 0,
    difficulty: -1,

    area_est: 0,
    area_exe: 0,
    area_per: 0,

    dias_est: 0,
    dias_exe: 0,

    vVoo_est: 0,
    vVoo_exe: 0,
    vVooO_per: 0,
    vVooR_per: 0,

    vC_est: 0,
    vC_exe: 0,
    vC_per: 0,

    vVooHA_est: 0,
    vVooHA_exe: 0,
    vVooHA_per: 0,

    vProf_est: 0,
    vProf_exe: 0,
    vProf_per: 0,

    vImp_est: 0,
    vImp_exe: 0,
    vImp_per: 0,

    cVoo_est: 0,
    cVoo_exe: 0,
    cVoo_per: 0,

    cVooHA_est: 0,
    cVooHA_exe: 0,
    cVooHA_per: 0,

    tVoo_est: 0,
    tVoo_exe: 0,
    tVoo_per: 0,

    tSolo_est: 0,
    tSolo_exe: 0,
    tSolo_per: 0,
    tSolo_valor: 0,

    tTotal_est: 0,
    tTotal_exe: 0,
    tTotal_per: 0,
    tTotal_valor: 0,

    tTran_est: 0,
    tTran_exe: 0,
    tTran_per: 0,
    tTran_valor: 0,

    tApl_est: 0,
    tApl_exe: 0,
    tApl_per: 0,
    tApl_valor: 0,

    tLoop_est: 0,
    tLoop_exe: 0,
    tLoop_per: 0,
    tLoop_valor: 0,

    totalApl_est: 0,
    totalApl_exe: 0,
    totalApl_per: 0,

    velLoop_est: 0,
    velLoop_exe: 0,

    velApl_est: 0,
    velApl_exe: 0,

    velTrans_est: 0,
    velTrans_exe: 0,

    dLoop_est: 0,
    dLoop_exe: 0,

    qtdFuel_est: 0,
    qtdFuel_exe: 0,
    qtdFuel_per: 0,

    rend_est: 0,
    rend_exe: 0,
    rend_per: 0,

    lhora_est: 0,
    lhora_exe: 0,

    ruler_a: false,
    stack: false,
    stack_value: [1, 1, 1],
    progress: false,
    tab: '0',

    base: [],

    task: null,
    itensTask: [],
    openTask: false,
    clear: false,
    downloadRelButton: false,
    addKm: false,
    valor: 0,
    time: 0,
    new: { msg: this.props.res.NOVA_AVALIACAO_CONFIRM, func: this.clear }
  };
  state = JSON.parse(JSON.stringify(this.base_state));

  tabItens = [
    { label: this.props.res.INFORMACAO, value: '0' },
    { label: 'asdasdasd', value: '1' },
  ];

  dificult = [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
  ];

  ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };

  dataEstJob = null;
  dataFullJob = null;

  featureGroupL = null;
  featureGroupP = null;
  featureGroupPoly = null;
  featureGroupPM = null;

  area = 0;

  componentDidMount() {
    this.icon = Img_mark;
    this.boot();
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  boot = () => {
    this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);

    L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);

    this.featureGroupL = L.featureGroup();
    this.featureGroupL.addTo(this.mymap);
    this.featureGroupP = L.featureGroup();
    this.featureGroupP.addTo(this.mymap);
    this.featureGroupPoly = L.featureGroup();
    this.featureGroupPoly.addTo(this.mymap);

    this.featureGroupPM = L.featureGroup();
    this.featureGroupPM.addTo(this.mymap);

    new L.Control.Draw({
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: false
    }).addTo(this.mymap);

    this.mymap.on('click', () => {
      this.handleClickAplMap();
    });

    this.mymap.on('draw:drawvertex', e => {
      if (this.drawCreateToolbar.type === 'polyline') {
        let layerIds = Object.keys(e.layers._layers);
        if (layerIds.length > 1) {
          let v2 = e.layers._layers[layerIds[1]];
          this.ruler_p.second.lat = v2._latlng.lat;
          this.ruler_p.second.lng = v2._latlng.lng;
          requestAnimationFrame(() => this.completeRuler());
        } else if (layerIds.length === 1) {
          let v1 = e.layers._layers[layerIds[0]];
          this.ruler_p.first.lat = v1._latlng.lat;
          this.ruler_p.first.lng = v1._latlng.lng;
        }
      }
    });

    this.mymap.on('moveend', (e) => {
      this.checkMarkers();
    });

    this.mymap.on('zoomend', () => {
      this.checkMarkers();
    });

    L.drawLocal.draw.handlers.polyline.tooltip.start = '';
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';

    this.loadTask(false);
    if (!this.props.guid) {
      //this.loadStorage();
    } else {
      this.checkJob();
    }

    this.list_markers();

  };

  loadStorage = () => {
    let c_estimate = JSON.parse(localStorage.getItem('c-estimate'));
    for (let key in this.base_state) {
      // skip loop if the property is from prototype
      if (!this.base_state.hasOwnProperty(key)) continue;

      if (!c_estimate) c_estimate = {};

      if (!c_estimate[key]) {
        c_estimate[key] = this.base_state[key];
      }
    }

    this.setState(c_estimate, () => {
      this.setState({ stack: false, ruler_a: false });
    });
    let task = localStorage.getItem('check-task');
    if (!isNaN(task)) {
      this.checkTask(task, false);
    }
  };

  checkJob = () => {
    let guid = this.props.guid;
    let apiUrl = Config.server + '/job_check/appraisal/' + guid;
    let method = 'GET';

    let options = {
      method: method,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          if (result.task && result.task.id) {
            if (result.job && result.job.id) {
              this.setState({ id_job: result.job.id, title: result.job.title });
            }

            if (result.data && result.data.estimate) {
              this.useData(JSON.parse(result.data.estimate));
            }

            if (result.data && result.data.appraisal) {
              this.useData(JSON.parse(result.data.appraisal));
            } else {
              this.loadTask(true);
            }
          } else {
            if (result.data && result.data.estimate) {
              this.useData(JSON.parse(result.data.estimate));
            }
            this.loadTask(true);
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else if (error.status === 400) {
            error.json().then(
              (errCode) => {
                notification.open({
                  message: this.props.res[errCode.code],
                  icon: <MaterialIcon icon="error" className="text-danger" />
                });
              });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  list_markers = () => {
    const apiUrl = Config.server + '/point/0';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            let m = [];
            for (let i = 0; i < result.length; i++) {
              const element = result[i];

              let tempMark = new marks();
              tempMark.id = element.id;
              tempMark.guid = element.guid;
              tempMark.name = element.name;
              tempMark.lat = element.lat;
              tempMark.lng = element.lng;
              tempMark.alt = element.height;
              tempMark.r = element.radios;
              tempMark.desc = element.icon;
              tempMark.type = element.type;
              for (let j = 0; j < img.length; j++) {
                if (img[j].desc.includes(element.icon)) {
                  tempMark.img = img[j].img;
                  break;
                }
              }
              if (tempMark.type === 'P' || tempMark.type === 'H' || tempMark.img) {
                m.push(tempMark)
                this.drawMarker(tempMark);
              }
            }
            this.setState({ markers: m });
          }
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  drawMarker = (marker) => {
    let dIcon = L.icon({
      iconUrl: marker.img,
      iconAnchor: marker.type === 'O' ? [25, 30] : [13, 33],
      popupAnchor: [0, -25]
    });
    let newLatLng = new L.LatLng(marker.lat, marker.lng);
    let myLayer = L.marker(newLatLng, { icon: dIcon });
    myLayer._leaflet_id = marker.guid;
    myLayer.bindPopup(marker.name);
    myLayer.addTo(this.featureGroupPM);

    if (marker.r > 0) {
      let myStyle = {
        "color": 'red',
        "weight": 4,
        "opacity": 0.5,
        "radius": marker.r
      };
      myLayer = L.circle(newLatLng, myStyle);
      myLayer._leaflet_id = marker.guid + 'r';
      myLayer.addTo(this.featureGroupPM);
    }
  }

  checkMarkers = () => {
    let mapBounds = this.mymap.getBounds();
    for (let i = this.state.markers.length - 1; i >= 0; i--) {
      let m = this.mymap._layers[this.state.markers[i].guid];
      if (this.mymap.getZoom() > 11) {
        if (!m) {
          this.drawMarker(this.state.markers[i]);
        } else {
          let shouldBeVisible = mapBounds.contains(m.getLatLng());
          if (m._icon && !shouldBeVisible) {
            this.featureGroupPM.removeLayer(m);
            let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
            if (mr)
              this.featureGroupP.removeLayer(mr);
          }
        }
      } else if (m) {
        this.featureGroupPM.removeLayer(m);
        let mr = this.mymap._layers[this.state.markers[i].guid + 'r'];
        if (mr)
          this.featureGroupP.removeLayer(mr);
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeTab = name => (event, newValue) => {
    this.setState({
      [name]: newValue ? newValue : event.target.value,
    });
  };

  zoomInL = () => {
    this.mymap.zoomIn();
  };

  zoomOutL = () => {
    this.mymap.zoomOut();
  };

  showMenu = () => {
    let dF = document.getElementById('divFloat');
    if (!dF.className.includes('div-listFloatP')) {
      dF.classList.toggle("div-listFloatP");
    } else {
      dF.classList.remove("div-listFloatP");
    }
  };

  handleStackClick = (tipo) => {
    let stack_value = this.state.stack_value;
    if (tipo === 1) {
      if (stack_value[0] === 1) {
        stack_value[0] = 0;
        tools.cssShow('aplicacao', false);
      } else {
        stack_value[0] = 1;
        tools.cssShow('aplicacao', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 2) {
      if (stack_value[1] === 1) {
        stack_value[1] = 0;
        tools.cssShow('caminho', false);
      } else {
        stack_value[1] = 1;
        tools.cssShow('caminho', true);
      }
      this.setState({ stack_value: stack_value });
    } else if (tipo === 3) {
      if (stack_value[2] === 1) {
        stack_value[2] = 0;
        tools.cssShow('area', false);
      } else {
        stack_value[2] = 1;
        tools.cssShow('area', true);
      }
      this.setState({ stack_value: stack_value });
    }
  };

  //upload de arquivos TXT e zjob
  loadFile = file => {
    let filePath = file.target.files[0];
    let ext = fileName => fileName.split(".").pop();

    if (ext(filePath.name.toLowerCase()) === 'zjob') {
      this.uploadFile(filePath);
    }
    document.getElementById('contained-button-file').value = null;
  };

  uploadFile = (file) => {
    this.setState({ progress: true });

    let apiUrl = Config.server + '/appraisal';
    let method = 'POST';

    const formData = new FormData();

    formData.append('file', file, 'teste.file');

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          this.useData(result);
          this.loadTask(result.size ? true : false);
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else if (error.status === 400) {
            notification.open({
              message: this.props.res[error.statusText],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  updatePer = (values, cost) => {
    const time = isNaN(this.state.time) ? 0 : this.state.time;
    let cVoo_temp = cost ? values[7][1] / 60 / 60 * cost : 0;

    let area_per = values[14][0] > 0 ? (values[14][1] * 100 / values[14][0] - 100).toFixed(2) * -1 : 0;
    let tVoo_per = values[0][0] > 0 ? (values[0][1] * 100 / values[0][0] - 100).toFixed(2) * -1 : 0;
    let cVoo_per = values[6][0] > 0 ? (cVoo_temp * 100 / values[6][0] - 100).toFixed(2) * -1 : 0;
    let totalApl_per = values[5][0] > 0 ? (values[5][1] * 100 / values[5][0] - 100).toFixed(2) * -1 : 0;
    let qtdFuel_per = !values[3][0] === 0 && !values[3][1] === 0 ? (values[3][1] * 100 / values[3][0] - 100).toFixed(2) * -1 : 0;

    let tTotal_per = values[7][0] > 0 ? (values[7][1] * 100 / values[7][0] - 100).toFixed(2) * -1 : 0;
    let tTotal_valor = ((cost * (values[7][0] / 60 / 60)) - (cost * (values[7][1] / 60 / 60))).toFixed(2);
    let tSolo_per = values[12][0] > 0 ? (values[12][1] * 100 / values[12][0] - 100).toFixed(2) * -1 : 0;
    let tSolo_valor = ((cost * (values[12][0] / 60 / 60)) - (cost * (values[12][1] / 60 / 60))).toFixed(2);
    let tTran_per = values[1][0] > 0 ? (values[1][1] * 100 / values[1][0] - 100).toFixed(2) * -1 : 0;

    let tTran_valor = ((cost * (values[1][0] / 60 / 60)) - (cost * (values[1][1] / 60 / 60))).toFixed(2);
    let tLoop_per = values[4][0] > 0 ? (values[4][1] * 100 / values[4][0] - 100).toFixed(2) * -1 : 0;

    let tLoop_valor = ((cost * (values[4][0] / 60 / 60)) - (cost * (values[4][1] / 60 / 60))).toFixed(2);
    let tApl_per = values[2][0] > 0 ? (values[2][1] * 100 / values[2][0] - 100).toFixed(2) * -1 : 0;

    let tApl_valor = ((cost * (values[2][0] / 60 / 60)) - (cost * (values[2][1] / 60 / 60))).toFixed(2);

    let vProf_exe = values[10][0] - cVoo_temp - values[8][0];
    let lhora_exe = vProf_exe / ((values[7][1] + time) / 3600);
    //let vPtemp = values[9][0] - vProf_exe;
    let vProf_per = (vProf_exe > 0 ? vProf_exe * 100 / values[9][0] - 100 : 0).toFixed(2);
    if (values[9][0] < 0) {
      vProf_per *= -1;
    }
    let vVooO_per = (values[10][0] > 0 ? values[9][0] * 100 / values[10][0] : 0).toFixed(2);
    let vVooR_per = (values[10][0] > 0 ? vProf_exe * 100 / values[10][0] : 0).toFixed(2);

    let vVooHA_temp = values[14][1] > 0 ? values[10][0] / values[14][1] : 0;
    let vVooHA_per = vVooHA_temp > 0 ? (vVooHA_temp * 100 / values[11][0] - 100).toFixed(2) : 0;
    let cVooHA_exe = values[14][1] > 0 ? cVoo_temp / values[14][1] : 0;
    let cVooHA_per = cVoo_temp > 0 ? (cVooHA_exe * 100 / values[13][0] - 100).toFixed(2) * -1 : 0;

    let vC_exe = cVoo_temp;
    let vC_per = vC_exe > 0 ? (vC_exe * 100 / values[15][0] - 100).toFixed(2) * -1 : 0;

    let rend_per = (values[16][0] > 0 ? values[16][1] * 100 / values[16][0] - 100 : 0).toFixed(2);

    this.setState({
      cVoo_exe: cost > 0 && values[0][1] > 0 ? cVoo_temp : 0,
      vImp_exe: values[8][0],
      vProf_exe: vProf_exe,
      lhora_exe: lhora_exe,
      vVoo_exe: values[10][0],
      vVooHA_exe: cost > 0 && values[0][1] > 0 ? vVooHA_temp : 0,

      vProf_per: vProf_per,
      vVooO_per: vVooO_per,
      vVooR_per: vVooR_per,
      area_per: area_per,

      vC_exe: vC_exe,
      vC_per: vC_per,

      tTotal_per: tTotal_per,
      tTotal_valor: tTotal_valor,
      tSolo_per: tSolo_per,
      tSolo_valor: tSolo_valor,
      tTran_per: tTran_per,
      tTran_valor: tTran_valor,
      tLoop_per: tLoop_per,
      tLoop_valor: tLoop_valor,
      tApl_per: tApl_per,
      tApl_valor: tApl_valor,

      tVoo_per: tVoo_per,
      cVoo_per: cVoo_per,
      totalApl_per: totalApl_per,
      qtdFuel_per: qtdFuel_per,

      cVooHA_exe: cVooHA_exe,
      vVooHA_per: vVooHA_per,
      cVooHA_per: cVooHA_per,

      rend_per: rend_per,
    }, () => {
      localStorage.setItem('c-estimate', JSON.stringify(this.state));
      this.setState({ downloadRelButton: true });
    });
  };

  markMap = (type, pos) => {
    let label = 'mark';
    if (type === 11) {
      if (this.state.base.length > 0) {
        this.featureGroupPoly.removeLayer(this.mymap._layers['base']);
      }
      this.setState({ base: pos });
      label = 'base';
    }
    let dIcon = L.icon({
      iconUrl: this.icon[type].img,
      iconAnchor: [13, 33]
    });
    let mark = L.marker(pos, { icon: dIcon });
    if (label === 'base') {
      mark._leaflet_id = label;
    }
    this.featureGroupPoly.addLayer(mark);
    this.mymap.fitBounds(this.featureGroupPoly.getBounds());
  };

  polyMap = (poly) => {
    const color_x = '#ff002b';
    let myStyle = {
      color: '#47839e',
      weight: 4,
      opacity: 0.5,
      fillColor: '#FF1493',
      fillOpacity: .7,
      className: 'area'
    };

    if (poly.type === 'X') {
      myStyle.color = color_x;
      myStyle.fillColor = color_x;
    }

    let myLayer = L.geoJSON(poly.poly, { style: myStyle });
    myLayer.addTo(this.featureGroupPoly);
  };

  abMap = (poly) => {
    if (poly.abData && poly.abData.line) {
      const myStyle = {
        "color": '#f5c749',
        "weight": 2,
        "opacity": 1
      };
      let myLayer = L.geoJSON(poly.abData.line, { style: myStyle });
      myLayer.addTo(this.featureGroupPoly);


      const latlngs = poly.abData.line.geometry.coordinates;
      let imgIcon = Img_mark[8].img;

      let dIcon = L.icon({
        iconUrl: imgIcon,
        iconAnchor: [9, 30]
      });
      let newLatLng = new L.LatLng(latlngs[0][1], latlngs[0][0]);
      myLayer = L.marker(newLatLng, { icon: dIcon });
      myLayer.addTo(this.featureGroupPoly);

      const bearing = poly.abData.bearing < 0 ? 360 + poly.abData.bearing : poly.abData.bearing;
      const pop = this.props.res.ANGULO + ": " + bearing.toFixed() + 'º<br> ' + this.props.res.ESTRATEGIA + ': ' + poly.abData.strategy + '<br> ' + this.props.res.LARGURA_FAIXA + ': ' + poly.abData.width + '<br> ' + this.props.res.FLUXO_APLICACAO + ': ' + poly.abData.setPoint;

      imgIcon = Img_mark[9].img;

      dIcon = L.icon({
        iconUrl: imgIcon,
        iconAnchor: [9, 30],
        popupAnchor: [9, -30]
      });
      newLatLng = new L.LatLng(latlngs[1][1], latlngs[1][0]);
      myLayer = L.marker(newLatLng, { icon: dIcon });
      myLayer.bindPopup(pop);
      myLayer.addTo(this.featureGroupPoly);

      imgIcon = Img_mark[22].img;

      dIcon = L.icon({
        iconUrl: imgIcon,
        iconAnchor: [-13, 35],
        popupAnchor: [9, -30]
      });
      dIcon.options.iconSize = [16, 16];
      newLatLng = new L.LatLng(latlngs[1][1], latlngs[1][0]);
      myLayer = L.marker(newLatLng, { icon: dIcon });
      myLayer.bindPopup(pop);
      myLayer.addTo(this.featureGroupPoly);

    }
  };

  aplMapCorrect = (poly) => {
    let myStyle = {
      color: '#32a852',
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao'
    };

    const pop =
      '<b>' + this.props.res.TIRO + ': ' + poly.title + '</b><br> '
      + this.props.res.LARGURA_FAIXA + ': ' + poly.faixa + ' m<br> '
      + this.props.res.COMPRIMENTO + ': ' + poly.comprimento.toFixed(2) + ' m<br> '
      + this.props.res.AREA + ': ' + poly.area.toFixed(3) + ' ha<br> '
      + this.props.res.VOLUME + ': ' + poly.volume.toFixed(3) + ' L<br> '
      + this.props.res.FLUXO_MEDIO + ': ' + poly.fMedio.toFixed(3) + ' L/ha';

    let myLayer = L.geoJSON(poly.poly, { style: myStyle });
    myLayer.on("click", () => { this.handleClickAplMap(myLayer); });
    myLayer.bindPopup(pop);
    myLayer.addTo(this.featureGroupP);
  };

  handleClickAplMap = (layer) => {
    this.featureGroupP.eachLayer((item) => {
      item.setStyle({ color: '#32a852' });
    });
    if (layer) {
      layer.setStyle({ color: '#25c450' });
    }
  };

  pathMap = (line) => {
    let myStyle = {
      color: '#d0d0d0',
      weight: 1,
      opacity: 1,
      smoothFactor: 1,
      className: 'caminho'
    };

    let myLayer = L.geoJSON(line, { style: myStyle });
    myLayer.addTo(this.featureGroupL);
  };

  aplMap = (poly) => {
    let myStyle = {
      color: "#ffd12b",
      fillOpacity: .9,
      opacity: 0,
      className: 'aplicacao'
    };

    let myLayer = L.geoJSON(poly, { style: myStyle });
    myLayer.addTo(this.featureGroupP);
  };

  regua = (start) => {
    if (this.state.create) {
      this.cancelCreate();
    }
    if (this.state.edit) {
      this.cancelEdit();
    }

    this.featureGroupPoly.removeLayer(this.mymap._layers['regua']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM1']);
    this.featureGroupPoly.removeLayer(this.mymap._layers['reguaM2']);
    if (!start && (this.state.ruler_a || this.ruler_p.first.lat)) {
      this.setState({ ruler_a: false });
      this.ruler_p = { first: { lat: null, lng: null }, second: { lat: null, lng: null } };
      if (this.drawCreateToolbar) {
        this.drawCreateToolbar.disable();
      }
    } else {
      this.setState({ ruler_a: true });
      this.drawCreateToolbar = new L.Draw.Polyline(this.mymap);
      this.drawCreateToolbar.enable();
      if (start) {
        this.drawCreateToolbar.addVertex(start);
      }
    }
  };

  completeRuler = () => {
    if (this.drawCreateToolbar) {
      this.drawCreateToolbar.disable();
    }
    let latlngs = [
      [this.ruler_p.first.lat, this.ruler_p.first.lng],
      [this.ruler_p.second.lat, this.ruler_p.second.lng]
    ];
    let latlngF = L.latLng([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    let latlngS = L.latLng([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    let text = latlngF.distanceTo(latlngS);


    let polyline = L.polyline(latlngs);

    polyline._leaflet_id = 'regua';
    polyline.bindTooltip(this.formatRegua(text), { permanent: true }).openTooltip()
    polyline.addTo(this.featureGroupPoly);

    let m1 = L.marker([this.ruler_p.first.lat, this.ruler_p.first.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    })
    m1.on('click', (e) => {
      this.regua([this.ruler_p.second.lat, this.ruler_p.second.lng]);
    })
    m1._leaflet_id = 'reguaM1';
    m1.addTo(this.featureGroupPoly);

    let m2 = L.marker([this.ruler_p.second.lat, this.ruler_p.second.lng], {
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: 'leaflet-div-icon leaflet-editing-icon my-own-icon',
      })
    });
    m2.on('click', (e) => {
      this.regua([this.ruler_p.first.lat, this.ruler_p.first.lng]);
    })
    m2._leaflet_id = 'reguaM2';
    m2.addTo(this.featureGroupPoly);
  };

  cancelRuler = () => {
    if (this.ruler_p.second.lat) {
      this.regua();
    }
  };

  formatRegua = (valor) => {
    let text = '';
    if (valor < 1000) {
      text = Math.round(valor) + ' m';
    } else {
      text = (Math.round(valor / 10) / 100) + ' km';
    }

    return text;
  };
  keyCell = 0;
  item_title = (res) => {
    return (<TableRow key={this.keyCell++}>
      <TableCell component="th" scope="row" colSpan={4} style={{ borderBottom: 0 }}>
        <div className="titleTextRel">
          {res}
        </div>
      </TableCell>
    </TableRow>);
  };

  item_value = (est, exe, per, valor, exe_valor, total_valor) => {
    const test = !(valor && valor !== 0);
    const per_temp = isNaN(exe) ? per : exe > 0 ? per : 0;
    const per_exe = exe_valor ? this.timePercentual(total_valor, exe_valor) : false;
    const exe_temp = per_exe && per_exe > 0 ? <span style={{ fontSize: '10px', marginBottom: '8px', position: 'absolute' }}>&nbsp;({per_exe}%)</span> : '';
    return (<TableRow key={this.keyCell++}>
      {test && <TableCell component="th" scope="row"></TableCell>}
      <TableCell align="right">{est}</TableCell>
      <TableCell align="left" style={{ position: 'relative' }}><span>{exe}</span>{exe_temp}</TableCell>
      {typeof per_temp !== 'undefined' && <TableCell align="right">
        {per_temp !== 0 &&
          <span className="contentPercRel">
            {per_temp > 0 ?
              <Arrow rotate="0" color="green"></Arrow>
              : per_temp < 0 ?
                <Arrow rotate="180" color="red"></Arrow>
                : null
            }{per_temp}%
          </span>
        }
      </TableCell>}
      {!test && <TableCell align="right">{valor}</TableCell>}
    </TableRow>);
  };

  checkTask = (item, pro) => {
    const temp_pro = pro;
    this.setState({ openTask: false, progress: pro });
    let apiUrl = Config.server + '/getResult/' + item;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result === 'file not found.') {
            this.loadTask(true);
          } else {
            this.setState({ task: item }, () => {
              let data = JSON.parse(result);
              if (data.guid_link) {
                window.history.replaceState(null, "Travicar", "/app/avaliacao/" + data.guid_link);
              }
              if (temp_pro) {
                this.setState({ valor: 0, time: 0 }, () => { this.useData(data) });;
              } else {
                this.useData(data);
              }
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  updateTask = (item, valor) => {
    let apiUrl = Config.server + '/getResult/' + item;

    const formData = new URLSearchParams();
    formData.append('addKm', valor);

    const options = {
      method: 'PUT',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {

        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  useData = (result) => {
    if (result && result.tVoo) {
      if (result.linha) {
        this.featureGroupL.eachLayer((layer) => {
          this.featureGroupL.removeLayer(layer);
        });

        this.featureGroupPoly.eachLayer((layer) => {
          this.featureGroupPoly.removeLayer(layer);
        });
        for (let i = 0; i < result.polygons.length; i++) {
          const element = result.polygons[i];
          this.polyMap(element);
        }

        this.pathMap(result.linha);
        this.featureGroupP.eachLayer((layer) => {
          this.featureGroupP.removeLayer(layer);
        });
        for (let i = 0; i < result.apl.aplicacao.length; i++) {
          const element = result.apl.aplicacao[i];
          this.aplMapCorrect(element);
        }

        for (let i = 0; i < result.fuel.arrFuel.length; i++) {
          const element = result.fuel.arrFuel[i];
          this.markMap(0, [element.pos[0][1], element.pos[0][0]]);
        }

        if (this.state.base.length > 0) {
          this.markMap(11, this.state.base);
        }

        if (result.polygons.length) {
          this.mymap.fitBounds(this.featureGroupPoly.getBounds());
        }

        for (let i = 0; i < result.polygons.length; i++) {
          const element = result.polygons[i];
          this.abMap(element);
        }

        const velAverage = (result.apl.tApl * result.apl.vApl + result.travel.tTravel * result.travel.vTravel + result.loop.tLoop * result.loop.vLoop) / (result.apl.tApl + result.travel.tTravel + result.loop.tLoop);
        this.setState({
          valor: result.addKm,
          title_sk: result.title,
          aeronavePrefixT: result.aeronavePrefix,
          customerNameT: result.customerName,
          cVoo_exe: result.cVoo,
          area_exe: result.apl.area_aplicada,
          tTotal_exe: result.tTotal,
          tVoo_exe: result.tVoo,
          tSolo_exe: result.tTotal - result.tVoo,
          tTran_exe: result.travel.tTravel + result.fuel.tFuelS,
          tApl_exe: result.apl.tApl,
          tFuel_exe: result.fuel.tFuelS,
          qtdFuel_exe: result.fuel.qtdFuel,
          tLoop_exe: result.loop.tLoop,
          totalApl_exe: Math.ceil(result.apl.totalApl),
          vC_exe: 0,
          dias_exe: result.days,
          velAverage: (velAverage * 3.6).toFixed(2),
          velApl_exe: (result.apl.vApl * 3.6).toFixed(2),
          velTrans_exe: (result.fuel.vFuelS * 3.6).toFixed(2),
          velLoop_exe: (result.loop.vLoop * 3.6).toFixed(2),
          rend_exe: (result.apl.area_aplicada / (result.tTotal / 3600)),
          progress: false
        }, () => {
          localStorage.setItem('c-estimate', JSON.stringify(this.state));
          this.addKm();
        });
        if (this.state.tVoo_est) {
          this.updatePer([
            [this.state.tVoo_est, result.tVoo],
            [this.state.tTran_est, result.travel.tTravel + result.fuel.tFuelS],
            [this.state.tApl_est, result.apl.tApl],
            [this.state.qtdFuel_est, result.fuel.qtdFuel],
            [this.state.tLoop_est, result.loop.tLoop],
            [this.state.totalApl_est, Math.ceil(result.apl.totalApl)],
            [this.state.cVoo_est, result.cVoo],                     //6
            [this.state.tTotal_est, result.tTotal],                 //7
            [this.state.vImp_est, result.vImp],                     //8
            [this.state.vProf_est, result.vProf],                   //9
            [this.state.vVoo_est, result.vVoo],                     //10
            [this.state.vVooHA_est, result.apl.area_aplicada],      //11
            [this.state.tSolo_est, result.tTotal - result.tVoo],    //12
            [this.state.cVooHA_est, result.apl.area_aplicada],      //13
            [this.state.area_est, result.apl.area_aplicada],        //14
            [this.state.vC_est, 0],                                 //15
            [this.state.rend_est, (result.apl.area_aplicada / (result.tTotal / 3600))],                               //16
          ], this.state.cost);
        }
        /////---------------------
      } else {
        this.area = result.apl.area_aplicada;
        let base = result.base ? result.base : [];
        if (base.length > 0) {
          this.markMap(11, base);
        }
        const temp_area = result.vVoo / result.apl.area_aplicada;
        const vCost = (result.vVoo - result.vProf - result.vImp);
        this.setState({
          aeronavePrefixO: result.aeronavePrefix,
          customerNameO: result.customerName,
          cost: result.cost,
          area_est: result.apl.area_aplicada,
          cVoo_est: result.cVoo,
          vImp_est: result.vImp,
          vProf_est: result.vProf,
          vVoo_est: result.vVoo,
          vVooHA_est: temp_area,
          tTotal_est: result.tTotal,
          tVoo_est: result.tVoo,
          tSolo_est: (result.tTotal - result.tVoo) > 0 ? (result.tTotal - result.tVoo) : 0,
          tTran_est: result.travel.tTravel,
          tApl_est: result.apl.tApl,
          tFuel_est: result.fuel.tFuelS,
          qtdFuel_est: result.fuel.qtdFuel,
          tLoop_est: result.loop.tLoop,
          totalApl_est: Math.ceil(result.apl.totalApl),
          cVooHA_est: vCost / result.apl.area_aplicada,
          vC_est: vCost,
          dias_est: Math.ceil((result.tTotal / 60 / 60) / result.days),
          velApl_est: result.apl.vApl,
          velTrans_est: result.travel.vTravel,
          velLoop_est: result.loop.vLoop,
          difficulty: result.difficulty,
          rend_est: (result.apl.area_aplicada / (result.tTotal / 3600)),
          lhora_est: result.vProf / (result.tTotal / 3600),
          progress: false
        }, () => { localStorage.setItem('c-estimate', JSON.stringify(this.state)); });
        if (this.state.tVoo_exe) {
          this.updatePer([
            [result.tVoo, this.state.tVoo_exe + this.state.time],                       //0
            [result.travel.tTravel, this.state.tTran_exe + this.state.time],            //1
            [result.apl.tApl, this.state.tApl_exe],                   //2
            [result.fuel.qtdFuel, this.state.qtdFuel_exe],            //3
            [result.loop.tLoop, this.state.tLoop_exe],                //4
            [Math.ceil(result.apl.totalApl), this.state.totalApl_exe],//5
            [result.cVoo, this.state.cVoo_exe],                       //6
            [result.tTotal, this.state.tTotal_exe + this.state.time],                   //7
            [result.vImp, this.state.vImp_exe],                       //8
            [result.vProf, this.state.vProf_exe],                     //9
            [result.vVoo, this.state.vVoo_exe],                       //10
            [temp_area, this.state.vVooHA_exe],                       //11
            [result.tTotal - result.tVoo, this.state.tSolo_exe],      //12
            [vCost / result.apl.area_aplicada, this.state.cVooHA_exe],//13
            [result.apl.area_aplicada, this.state.area_exe],          //14
            [vCost, 0],      //15
            [(result.apl.area_aplicada / (result.tTotal / 3600)), this.state.rend_exe],          //14
          ], result.cost);
        }
      }
    } else {
      if (!result) {
        this.setState({ progress: false });
        notification.open({
          message: this.props.res.PROCESSANDO,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
      }
    }
  };

  downloadRel = () => {
    let apiUrl = Config.server + '/relavaliacao';
    let method = 'POST';

    const formData = new URLSearchParams();

    formData.append('culture', this.props.res.LINGUA);
    formData.append('moneyUnit', this.props.res.SIMBOLO_MOEDA);

    formData.append('title', this.state.title === '...' ? this.state.title_sk : this.state.title);
    formData.append('aeronave', (
      (this.state.aeronavePrefixT && this.state.aeronavePrefixO) && this.state.aeronavePrefixT !== this.state.aeronavePrefixO ?
        this.state.aeronavePrefixO + ' / ' + this.state.aeronavePrefixT :
        this.state.aeronavePrefixO ? this.state.aeronavePrefixO : this.state.aeronavePrefixT
    ));

    formData.append('customerName', (
      (this.state.customerNameT && this.state.customerNameO) && this.state.customerNameT !== this.state.customerNameO ?
        this.state.customerNameO + ' / ' + this.state.customerNameT :
        this.state.customerNameO ? this.state.customerNameO : this.state.customerNameT
    ));

    formData.append('tTotalEst', this.state.tTotal_est);
    formData.append('tTotalExe', this.state.tTotal_exe + this.state.time);
    formData.append('tTotalPer', this.state.tTotal_per);
    formData.append('tTotalVal', this.state.tTotal_valor);

    formData.append('tSoloEst', this.state.tSolo_est);
    formData.append('tSoloExe', this.state.tSolo_exe);
    formData.append('tSoloPer', this.state.tSolo_per);
    formData.append('tSoloVal', this.state.tSolo_valor);

    formData.append('tTranEst', this.state.tTran_est);
    formData.append('tTranExe', this.state.tTran_exe + this.state.time);

    formData.append('tTranPer', this.state.tTran_per);
    formData.append('tTranVal', this.state.tTran_valor);

    formData.append('tLoopEst', this.state.tLoop_est);
    formData.append('tLoopExe', this.state.tLoop_exe);
    formData.append('tLoopPer', this.state.tLoop_per);
    formData.append('tLoopVal', this.state.tLoop_valor);

    formData.append('tAplEst', this.state.tApl_est);
    formData.append('tAplExe', this.state.tApl_exe);
    formData.append('tAplPer', this.state.tApl_per);
    formData.append('tAplVal', this.state.tApl_valor);

    formData.append('vVooPer', this.state.vVoo_per);

    formData.append('vVooEst', this.state.vVoo_est);
    formData.append('vVooExe', this.state.vVoo_exe);

    formData.append('vCEst', this.state.vC_est);
    formData.append('vCExe', this.state.vC_exe);
    formData.append('vCPer', this.state.vC_per);

    formData.append('vImpEst', this.state.vImp_est);
    formData.append('vImpExe', this.state.vImp_exe);

    formData.append('vProfEst', this.state.vProf_est);
    formData.append('vProfExe', this.state.vProf_exe);
    formData.append('vProfPer', this.state.vProf_per);

    formData.append('vVooOPer', this.state.vVooO_per);
    formData.append('vVooRPer', this.state.vVooR_per);

    formData.append('areaEst', this.state.area_est);
    formData.append('areaExe', this.state.area_exe);
    formData.append('areaPer', this.state.area_per);

    formData.append('vVooHaEst', this.state.vVooHA_est);
    formData.append('vVooHaExe', this.state.vVooHA_exe);
    formData.append('vVooHaPer', this.state.vVooHA_per);

    formData.append('cVooHaEst', this.state.cVooHA_est);
    formData.append('cVooHaExe', this.state.cVooHA_exe);
    formData.append('cVooHaPer', this.state.cVooHA_per);

    formData.append('totalAplEst', this.state.totalApl_est);
    formData.append('totalAplExe', this.state.totalApl_exe);
    formData.append('totalAplPer', this.state.totalApl_per);

    formData.append('qtdFuelEst', this.state.qtdFuel_est);
    formData.append('qtdFuelExe', this.state.qtdFuel_exe);
    formData.append('qtdFuelPer', this.state.qtdFuel_per);

    formData.append('velAplEst', this.state.velApl_est);
    formData.append('velAplExe', this.state.velApl_exe);

    formData.append('velTransEst', this.state.velTrans_est);
    formData.append('velTransExe', this.state.velTrans_exe);

    formData.append('velLoopEst', this.state.velLoop_est);
    formData.append('velLoopExe', this.state.velLoop_exe);

    formData.append('diasEst', this.state.dias_est);
    formData.append('diasExe', JSON.stringify(this.state.dias_exe));
    formData.append('dificuldade', isNaN(this.state.difficulty) && this.state.difficulty > 0 ? 0 : this.dificult[this.state.difficulty - 1]);
    formData.append('rendEst', this.state.rend_est);
    formData.append('rendExe', this.state.rend_exe);
    formData.append('rendPer', this.state.rend_per);

    formData.append('lHoraEst', this.state.lhora_est);
    formData.append('lHoraExe', this.state.lhora_exe);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.blob();
      })
      .then(
        (result) => {
          this.setState({ progress: false });
          let file_name = "AVAL" + (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).replace(/[^a-z0-9_]/gi, "") + '.pdf';
          if (result.type === 'application/pdf' && result.size > 0) {
            saveAs(result, file_name);
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            console.log(error);
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  loadTask = (open) => {
    let apiUrl = Config.server + '/task/appraisal'

    if (this.props.guid) {
      apiUrl += '/' + this.props.guid
    }

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result.length > 0) {
            setTimeout(() => {
              if (this.state.openTask) {
                this.loadTask(true);
              }
            }, 3000);
            this.setState({
              itensTask: result,
              openTask: open
            });
          } else {
            this.setState({ itensTask: [] });
          }
        },
        (error) => {
          this.setState({ progress: false });
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            console.log(error);
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  clear = () => {
    localStorage.setItem('c-estimate', JSON.stringify(this.base_state));
    this.setState(JSON.parse(JSON.stringify(this.base_state)));

    this.featureGroupL.clearLayers();
    this.featureGroupP.clearLayers();
    this.featureGroupPoly.clearLayers();
    localStorage.setItem('check-task', null);
  };

  addKm = () => {
    let time = isNaN(this.state.velTrans_exe) || (!isNaN(this.state.velTrans_exe) && Number(this.state.velTrans_exe) === 0) ? 0 : this.state.valor / Number(this.state.velTrans_exe) * 3600;
    time = isNaN(time) ? 0 : time;
    let rend = (this.state.area_exe / ((this.state.tTotal_exe + time) / 3600))
    this.setState({ time: time, rend_exe: rend, addKm: false },
      this.updatePer([
        [this.state.tVoo_est, this.state.tVoo_exe + time],                     //0
        [this.state.tTran_est, this.state.tTran_exe + time],                   //1
        [this.state.tApl_est, this.state.tApl_exe],                     //2
        [this.state.qtdFuel_est, this.state.qtdFuel_exe],               //3
        [this.state.tLoop_est, this.state.tLoop_exe],                   //4
        [this.state.totalApl_est, this.state.totalApl_exe],             //5
        [this.state.cVoo_est, this.state.cVoo_exe],                     //6
        [this.state.tTotal_est, this.state.tTotal_exe + time],                 //7
        [this.state.vImp_est, this.state.vImp_exe],                     //8
        [this.state.vProf_est, this.state.vProf_exe],                   //9
        [this.state.vVoo_est, this.state.vVoo_exe],                     //10
        [this.state.vVooHA_est, this.state.vVooHA_exe],                 //11
        [this.state.tSolo_est, this.state.tSolo_exe],                   //12
        [this.state.cVooHA_est, this.state.cVooHA_exe],                 //13
        [this.state.area_est, this.state.area_exe],                     //14
        [this.state.vC_est, this.state.vC_exe],                         //15
        [this.state.rend_est, rend],                     //16
      ], this.state.cost)
    );
    if (this.state.task) {
      //this.updateTask(this.state.task, this.state.valor);
    }
  };

  callNew = () => {
    this.setState({ clear: true, new: { msg: this.props.res.NOVA_AVALIACAO_CONFIRM, func: this.clear } });
  };

  timePercentual = (timeTotal, timeParcial) => {
    return Math.round(100 * timeParcial / timeTotal);
  };

  task_title = '...';
  checkTitle = (task, i) => {
    if (this.task_title !== task.job_title) {
      if (this.state.itensTask.length - 1 === i) {
        this.task_title = '...';
      } else {
        this.task_title = task.job_title;
      }
      return true;
    }
    if (this.state.itensTask.length - 1 === i) {
      this.task_title = '...';
    }
    return false;
  };

  render() {
    const { res } = this.props;

    return (
      <div className="gridEst maxH">
        <div className="divContent">
          <div className="containerTitle">
            <div><h1>{res.AVALIACAO}</h1></div>
            <div className="centerTop"></div>
            <div>
              <Button variant="contained" color="primary" disabled={!this.state.downloadRelButton} style={{ marginRight: '.4rem' }} className="popUpButton" onClick={event => this.setState({ addKm: !this.state.addKm })} title={res.ADD_TRANSLADO}>
                <MaterialIcon icon="open_in_full" className="iconMap highlightSVG" />
              </Button>
              <Button variant="contained" color="primary" style={{ marginRight: '.4rem' }} disabled={!this.state.downloadRelButton} className="popUpButton" onClick={event => this.downloadRel()} title={res.DOWNLOADAVALIACAO}>
                <SvgIcon viewBox="0 0 1000 1000" className="iconArrow"
                  style={{ color: this.state.downloadRelButton ? 'white' : '#A6A6A6', transform: 'rotate(180deg)', width: '2rem', height: '2rem' }}>
                  <path id="polygon" d="M326.156 867.15l462.329 0c20.0054,0 29.8386,-9.83318 29.8386,-29.8047l0 -633.189c0,-20.0054 -9.83318,-29.8386 -29.8386,-29.8386l-462.329 0c-19.8359,0 -29.8386,9.83318 -29.8386,29.8386l0 633.189c0,19.9715 10.0027,29.8047 29.8386,29.8047zm166.52 -540.486l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm-63.5087 0l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm0 63.0001l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm63.5087 0l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm-63.5087 66.3231l46.8263 0 0 -32.6529 -46.8263 0 0 32.6529zm63.5087 0l195.307 0 0 -32.6529 -195.307 0 0 32.6529zm68.8322 165.503l98.1622 55.8457c9.83318,-17.3267 14.648,-35.8402 14.648,-55.8457 0,-31.161 -10.986,-57.8123 -32.992,-79.6487 -22.006,-22.006 -48.4877,-32.992 -79.8183,-32.992 -31.161,0 -57.8462,10.986 -79.8522,32.992 -22.006,21.8364 -32.992,48.4877 -32.992,79.6487 0,31.0254 10.986,57.5071 32.992,79.6826 22.006,22.1755 48.6912,33.1615 79.8522,33.1615l0 -112.844zm20.9887 31.3305l9.83318 112.336c34.5178,-3.32294 61.8473,-18.8187 81.9883,-46.8263l-91.8215 -65.5093zm-237.319 163.163l0 -587.99 426.828 0 0 587.99 -426.828 0zm-96.6703 4.84877l47.8096 0 0 -44.0119 -31.161 0 0 -504.306 31.161 0 0 -46.0125 -47.8096 0c-16.852,0 -25.3289,8.68032 -25.3289,26.0071l0 542.825c0,16.9877 8.47688,25.4984 25.3289,25.4984z" />
                </SvgIcon>
              </Button>
              <Button variant="contained" color="primary" disabled={this.state.itensTask.length === 0} className="popUpButton" onClick={event => this.loadTask(true)} title={res.EXIBIR_AVALIACAO}>
                <MaterialIcon icon="settings" className="iconMap highlightSVG" />
              </Button>
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Dialog onClose={event => this.setState({ openTask: false })} aria-labelledby="simple-dialog-title" open={this.state.openTask}>
            <DialogTitle id="simple-dialog-title">Processando</DialogTitle>
            <List>
              {this.state.itensTask.map((task, i) => (
                <>
                  {this.checkTitle(task, i) &&
                    <div style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)', padding: '0 1rem', fontSize: '14px', backgroundColor: '#f2f2f2' }}>{task.job_title}</div>
                  }
                  <ListItem button onClick={(event) => task.progress === 100 && this.checkTask(task.id, true)} key={task.id}>
                    <ListItemText primary={task.title} />
                    <div style={{ height: '1rem', width: '2.5rem', textAlign: 'right', alignSelf: 'baseline', marginTop: '.3rem' }}>{task.progress + '%'}</div>
                  </ListItem>
                </>
              ))}
            </List>
          </Dialog>
          <Dialog
            open={this.state.clear}
            onClose={() => this.setState({ clear: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.new.msg}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => this.state.new.func()} color="primary">
                {res.SIM}
              </Button>
              <Button onClick={() => this.setState({ clear: false })} color="primary" autoFocus>
                {res.NAO}
              </Button>
            </DialogActions>
          </Dialog>
          <Backd style={{ zIndex: 1002, color: '#fff' }} open={this.state.progress}>
            <CircularProgress color="inherit" disableShrink />
          </Backd>
          <div className="rowMapCO maxVH">
            <div id="divFloat" className="div-list_p divContentList list-p">
              <div style={{ padding: '1rem' }}>
                <div>
                  <h2 title={
                    this.state.title_sk && this.state.title === '...' ?
                      this.state.title_sk : this.state.title
                  } >{
                      (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).length > 30 ?
                        (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title).substring(0, 27) + '...' :
                        (this.state.title_sk && this.state.title === '...' ? this.state.title_sk : this.state.title)}</h2>
                </div>
                {this.state.title !== '...' && this.state.title !== this.state.title_sk &&
                  <div style={{ textAlign: 'right', marginTop: '-1rem' }}>
                    <h4 style={{ color: '#bcbcbc' }}>{this.state.title_sk}</h4>
                  </div>
                }
              </div>
              <div className="div-list_h">
                <div className="div-list_box">
                  <div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}><h3>{res.ANALISE_TEMPO}</h3></div>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {this.state.tTotal_valor === 0 && <TableCell></TableCell>}
                        <TableCell align="right">{res.ORCAMENTO}</TableCell>
                        <TableCell align="left">{res.REALIZADO}</TableCell>
                        <TableCell align="center"><b>%</b></TableCell>
                        {this.state.tTotal_valor !== 0 && <TableCell align="center">R$</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.item_title(res.TEMPO_TOTAL)}
                      {this.item_value(
                        tools.fancyTimeFormat(this.state.tTotal_est),
                        tools.fancyTimeFormat(isNaN(this.state.time) ? this.state.tTotal_exe : this.state.tTotal_exe + this.state.time) +
                        ((!isNaN(this.state.time) && Number(this.state.time) !== 0) ? ' *' : ''),
                        this.state.tTotal_per,
                        this.state.tTotal_valor)}
                      {this.item_title(res.TEMPO_SOLO)}
                      {this.item_value(
                        tools.fancyTimeFormat(this.state.tSolo_est),
                        tools.fancyTimeFormat(this.state.tSolo_exe),
                        this.state.tSolo_per,
                        this.state.tSolo_valor,
                        this.state.tSolo_exe,
                        isNaN(this.state.time) ? this.state.tTotal_exe : this.state.tTotal_exe + this.state.time)}
                      {this.item_title(res.TEMPO_TRANSLADO)}
                      {this.item_value(
                        tools.fancyTimeFormat(this.state.tTran_est),
                        tools.fancyTimeFormat(isNaN(this.state.time) ? this.state.tTran_exe : this.state.tTran_exe + this.state.time) +
                        ((!isNaN(this.state.time) && Number(this.state.time) !== 0) ? ' *' : ''),
                        this.state.tTran_per,
                        this.state.tTran_valor,
                        isNaN(this.state.time) ? this.state.tTran_exe : this.state.tTran_exe + this.state.time,
                        isNaN(this.state.time) ? this.state.tTotal_exe : this.state.tTotal_exe + this.state.time)}
                      {this.item_title(res.TEMPO_BALAO)}
                      {this.item_value(
                        tools.fancyTimeFormat(this.state.tLoop_est),
                        tools.fancyTimeFormat(this.state.tLoop_exe),
                        this.state.tLoop_per,
                        this.state.tLoop_valor,
                        this.state.tLoop_exe,
                        isNaN(this.state.time) ? this.state.tTotal_exe : this.state.tTotal_exe + this.state.time)}
                      {this.item_title(res.TEMPO_APLICACAO)}
                      {this.item_value(
                        tools.fancyTimeFormat(this.state.tApl_est),
                        tools.fancyTimeFormat(this.state.tApl_exe),
                        this.state.tApl_per,
                        this.state.tApl_valor,
                        this.state.tApl_exe,
                        isNaN(this.state.time) ? this.state.tTotal_exe : this.state.tTotal_exe + this.state.time)}
                    </TableBody>
                  </Table>
                  {this.state.difficulty >= 0 ?
                    <div style={{ width: '100%', marginTop: '1rem' }}>
                      <div style={{ marginLeft: '1.5rem', marginRight: '0.5rem' }}>
                        <Slider res={res} value={this.state.difficulty ? this.state.difficulty : 0} />
                      </div>
                    </div> :
                    <div style={{ width: '100%', marginTop: '1rem' }}></div>
                  }
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <h3>{res.ANALISE_FINANCEIRA}</h3>
                    </div>
                  </div>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          {res.ORCAMENTO}
                        </TableCell>
                        <TableCell align="left">
                          {res.REALIZADO}
                        </TableCell>
                        <TableCell align="center"><b>%</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.item_title(res.VALOR_SUGERIDO)}
                      {this.item_value(
                        (this.state.vVoo_est ? this.state.vVoo_est.toFixed(2) : 0),
                        (this.state.vVoo_exe ? this.state.vVoo_exe.toFixed(2) : 0),
                        0)}
                      {this.item_title(res.VALOR)}
                      {this.item_value(
                        (this.state.vC_est ? this.state.vC_est.toFixed(2) : 0),
                        (this.state.vC_exe ? this.state.vC_exe.toFixed(2) : 0),
                        this.state.vC_per)}
                      {this.item_title(res.IMPOSTOV)}
                      {this.item_value(
                        (this.state.vImp_est ? this.state.vImp_est.toFixed(2) : 0),
                        (this.state.vImp_exe ? this.state.vImp_exe.toFixed(2) : 0),
                        0)}
                      {this.item_title(res.MOEDA_LUCRO)}
                      {this.item_value(
                        (this.state.vProf_est ? this.state.vProf_est.toFixed(2) : 0),
                        (this.state.vProf_exe ? this.state.vProf_exe.toFixed(2) : 0),
                        this.state.vProf_per)}
                      {this.item_title(res.PER_LUCRO)}
                      <TableRow key={this.keyCell++}>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          {this.state.vVooO_per !== 0 ?
                            <span className="contentPercRel" style={{ display: 'inline-flex' }}>
                              {this.state.vVooO_per > 0 ?
                                <Arrow rotate="0" color="green"></Arrow>
                                :
                                <Arrow rotate="180" color="red"></Arrow>
                              }{this.state.vVooO_per}%
                            </span> : 0
                          }
                        </TableCell>
                        <TableCell align="left">
                          {this.state.vVooR_per !== 0 ?
                            <span className="contentPercRel" style={{ display: 'inline-flex', marginLeft: '-5px' }}>
                              {this.state.vVooR_per > 0 ?
                                <Arrow rotate="0" color="green"></Arrow>
                                :
                                <Arrow rotate="180" color="red"></Arrow>
                              }{this.state.vVooR_per}%
                            </span> : 0
                          }
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}><h3>{res.ANALISE_GERAL}</h3></div>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">{res.ORCAMENTO}</TableCell>
                        <TableCell align="left">{res.REALIZADO}</TableCell>
                        <TableCell align="center"><b>%</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.item_title(res.RENDIMENTO)}
                      {this.item_value(
                        (this.state.rend_est ? this.state.rend_est.toFixed(2) : 0),
                        (this.state.rend_exe ? this.state.rend_exe.toFixed(2) : 0),
                        0)}
                      {this.item_title(res.MOEDA_LUCRO_HORA)}
                      {this.item_value(
                        (this.state.lhora_est ? this.state.lhora_est.toFixed(2) : 0),
                        (this.state.lhora_exe ? this.state.lhora_exe.toFixed(2) : 0),
                        0)}
                      {this.item_title(res.TOTAL_DIAS)}
                      {this.item_value(
                        (this.state.dias_est && this.state.dias_est > 0 ?
                          (this.state.dias_est > 1 ?
                            this.state.dias_est + ' ' + res.DIAS : this.state.dias_est + ' ' + res.DIA)
                          : 0),
                        (this.state.dias_exe && this.state.dias_exe.length > 0 ?
                          (this.state.dias_exe.length > 1 ?
                            this.state.dias_exe.length + ' ' + res.DIAS : this.state.dias_exe.length + ' ' + res.DIA)
                          : 0),
                        0)}
                      {this.item_title(res.TOTAL_HECTARE)}
                      {this.item_value(
                        (this.state.area_est ? this.state.area_est.toFixed(1) : 0),
                        (this.state.area_exe ? this.state.area_exe.toFixed(1) : 0),
                        this.state.area_per)}
                      {this.item_title(res.VALOR_SUGERIDO_HA)}
                      {this.item_value(
                        (this.state.vVooHA_est ? this.state.vVooHA_est.toFixed(2) : 0),
                        (this.state.vVooHA_exe ? this.state.vVooHA_exe.toFixed(2) : 0),
                        this.state.vVooHA_per)}
                      {this.item_title(res.CUSTO_SUGERIDO_HA)}
                      {this.item_value(
                        (this.state.cVooHA_est ? this.state.cVooHA_est.toFixed(2) : 0),
                        (this.state.cVooHA_exe ? this.state.cVooHA_exe.toFixed(2) : 0),
                        this.state.cVooHA_per)}
                      {this.item_title(res.COMSUMO_TOTAL)}
                      {this.item_value(
                        this.state.totalApl_est,
                        this.state.totalApl_exe,
                        this.state.totalApl_per)}
                      {this.item_title(res.REABASTECIMENTO)}
                      {this.item_value(
                        this.state.qtdFuel_est,
                        this.state.qtdFuel_exe,
                        this.state.qtdFuel_per)}
                      {this.item_title(res.VELOCIDADE_MEDIA)}
                      {this.item_value(
                        this.state.velApl_est,
                        this.state.velAverage,
                        0)}
                      {this.item_title(res.VELOCIDADE_APLICACAO)}
                      {this.item_value(
                        this.state.velApl_est,
                        this.state.velApl_exe,
                        0)}
                      {this.item_title(res.VELOCIDADE_TRANSLADO)}
                      {this.item_value(
                        this.state.velTrans_est,
                        this.state.velTrans_exe,
                        0)}
                      {this.item_title(res.VELOCIDADE_MEDIA_BALAO)}
                      {this.item_value(
                        this.state.velLoop_est,
                        this.state.velLoop_exe,
                        0)}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="divContent divRelative map-p">
              <div className="containerTop">
                <div className="leftTop">
                  <Button className="buttonS buttonMenu buttonMenuSmall" onClick={event => this.showMenu()}>
                    <MaterialIcon icon="menu" className="iconMapF" />
                  </Button>
                  <Button className={this.state.ruler_a ? "buttonS buttonMenuHL" : "buttonS buttonMenu"} onClick={event => this.regua()}>
                    <MaterialIcon icon="settings_ethernet" className="iconMap highlightSVG" />
                  </Button>
                </div>
                <div className="centerTop"></div>
                <div className="rightTop">
                  <label htmlFor="contained-button-file" style={{ marginBottom: 0, display: 'inline' }}>
                    <Button className="buttonS buttonMenu" onClick={event => this.setState({ stack: !this.state.stack })}>
                      <MaterialIcon icon="layers" className="iconMap" />
                    </Button>
                  </label>
                </div>
              </div>

              <div className="wrapperMap">
                <div id="mapid"></div>
                <div className="zoomB">
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomInL()}>
                    <MaterialIcon icon="add" className="iconMap" />
                  </Button>
                  <Button className="buttonS buttonMenu" onClick={event => this.zoomOutL()}>
                    <MaterialIcon icon="remove" className="iconMap" />
                  </Button>
                </div>

                <div className={this.state.addKm ? "divContent floatDiv floatDivItens popUpF" : "sr-only"}>
                  <div style={{ overflowY: 'auto' }}>
                    <div>
                      <TextField
                        id="list_name"
                        label={res.ADD_TRANSLADO}
                        margin="none"
                        className="textI"
                        size="small"
                        style={{ width: '12rem' }}
                        value={this.state.valor}
                        onChange={this.handleChange("valor")}
                        onKeyDown={e => {
                          if (e.keyCode === 13) { this.addKm() }
                        }}
                        type="number"
                        onInput={(e) => {
                          e.target.value = e.target.value ? ((e.target.value - 0) === 0 || e.target.value.slice(-1) === '0' ? e.target.value : e.target.value - 0).toString().slice(0, 10) : 0
                        }}
                      />
                    </div>
                  </div>
                  <div className="divForm">
                    <Button size="small" className="popUpButton" style={{ marginRight: '1rem' }} onClick={event => this.addKm()}>
                      {res.ADICIONAR}
                    </Button>
                    <Button size="small" className="popUpButton" onClick={event => this.setState({ addKm: !this.state.addKm })}>
                      {res.CANCELAR}
                    </Button>
                  </div>
                </div>

                {this.state.stack &&
                  <div className="divContent floatW divS" onfocusout={event => this.setState({ stack: false })}>
                    <List
                      component="nav"
                      aria-labelledby="stack"
                    >
                      <ListItem key={3} button
                        className={(this.state.stack_value[2] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(3)}
                      >
                        <ListItemText id={3} primary="Area" />
                      </ListItem>

                      <ListItem key={2} button
                        className={(this.state.stack_value[1] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(2)}
                      >
                        <ListItemText id={2} primary="Caminho" />
                      </ListItem>

                      <ListItem key={1} button
                        className={(this.state.stack_value[0] === 1) ? 'SelItem' : ''}
                        onClick={event => this.handleStackClick(1)}
                      >
                        <ListItemText id={1} primary="Aplicação" />
                      </ListItem>
                    </List>
                  </div>}
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { guid } = useParams();
  return (
    <div className="rowForm maxH">
      <TextFields1 link={link} guid={guid} res={res} />
    </div>)
}
export default Box;
