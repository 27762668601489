import React from 'react';
import 'leaflet-draw';
import PropTypes from 'prop-types';
import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import SliderComponent from '../../components/Slider/barSlider';

import AssessmentIcon from '@material-ui/icons/Assessment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
    },
    tableRow: {
        backgroundColor: theme.palette.background.paper,
        '&:nth-of-type(odd)': {
            backgroundColor: '#343a40',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#343a40',
        },
        '& > *': {
            padding: '0.5rem',
            borderBottom: '2px solid #ff8c00',
        },
    },
    tableCell: {
        textAlign: 'center',
        color: 'white',
    },
    tableHeadCell: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#fa7d34',
        backgroundColor: '#343a40',
        borderBottom: '1px solid #fa7d34'
    },
    summaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '8px',
        color: 'white',
    },
    sliderLabelCustom: {
        color: 'white',
        fontSize: '1rem',
        textAlign: 'left',
    },
});

class DiaryQuality extends React.Component {
    state = {
        jobDetail: [],
        allJobs: [],
        jobStatus: {},
        isLoading: true,
        averageMetrics: {
            acerto: 0,
            uniformidade: 0,
            falha: 0,
            perda: 0,
            velocidade: 0,
            altura: 0,
            fluxo: 0,
            temperatura: 0,
            umidade: 0,
            vento: 0,
            deltaT: 0,
        },
        selectedAlerts: [],
    };

    componentDidMount() {
        this.readJobLink();
        this.readAlerts();
    }

    readJobLink = () => {
        const apiUrl = Config.server + `/job_link`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                const jobStatus = {};
                const filterJobs = new Map();

                result.forEach(job => {
                    if (job.guid) {
                        const validJobs = filterJobs.get(job.id_job);
                        if (!validJobs || job.id > validJobs.id) {
                            filterJobs.set(job.id_job, job);
                        }
                    }
                    jobStatus[job.id] = job.statusJob;
                });
                const allValidJobs = Array.from(filterJobs.values());

                this.setState({
                    allJobs: allValidJobs,
                    jobStatus: jobStatus,
                }, () => {
                    this.readJobs();
                });
            })
            .catch((error) => {
                console.error(error);
                notification.open({
                    message: 'Erro',
                    description: 'Erro ao buscar dados do job_link.',
                    icon: <MaterialIcon icon="error" className="text-danger" />,
                });
            });
    };

    readJobs = () => {
        let date = new Date();
        let dateIniTimestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
        let dateEndTimestamp = dateIniTimestamp + 86400000;

        const apiUrl = `${Config.server}/job_detail/${dateIniTimestamp}/${dateEndTimestamp}`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                const jobDetailsWithMetrics = result.map(job => {
                    const metrics = this.calculateMetrics(job);
                    return {
                        ...job,
                        ...metrics,
                    };
                });

                const filteredJobs = Object.values(
                    jobDetailsWithMetrics.reduce((acc, job) => {
                        if (!acc[job.title] || new Date(acc[job.title].date) < new Date(job.date)) {
                            acc[job.title] = job;
                        }
                        return acc;
                    }, {})
                );

                this.setState(
                    {
                        jobDetail: filteredJobs,
                        isLoading: false,
                    },
                    () => {
                        const averageMetrics = this.calculateAverageMetrics();
                        this.setState({ averageMetrics });
                    }
                );
            })
            .catch((error) => {
                console.error(error);
                notification.open({
                    message: 'Erro',
                    description: 'Erro ao buscar dados.',
                    icon: <MaterialIcon icon="error" className="text-danger" />,
                });
            });
    };

    calculateMetrics = (job) => {
        const cVelocidade = job.correctSpeed + job.acceptableSpeed / 2;
        const tVelocidade = job.correctSpeed + job.acceptableSpeed + job.unacceptableSpeed;
        const cAltura = job.correctHeight + job.acceptableHeight / 2;
        const tAltura = job.correctHeight + job.acceptableHeight + job.unacceptableHeight;
        const cFluxo = job.correctFlow + job.acceptableFlow / 2;
        const tFluxo = job.correctFlow + job.acceptableFlow + job.unacceptableFlow;
        const cTemperatura = job.correctTemperature + job.acceptableTemperature / 2;
        const tTemperatura = job.correctTemperature + job.acceptableTemperature + job.unacceptableTemperature;
        const cUmidade = job.correctHumidity + job.acceptableHumidity / 2;
        const tUmidade = job.correctHumidity + job.acceptableHumidity + job.unacceptableHumidity;
        const cVelocidadeV = job.correctWindSpeed + job.acceptableWindSpeed / 2;
        const tVelocidadeV = job.correctWindSpeed + job.acceptableWindSpeed + job.unacceptableWindSpeed;
        const cDeltaT = job.correctDeltaT + job.acceptableDeltaT / 2;
        const tDeltaT = job.correctDeltaT + job.acceptableDeltaT + job.unacceptableDeltaT;

        const gAcerto = Math.round(job.spray_in / job.spray * 100);
        const gUniformidade = Math.round((job.spray_in - job.spray_over) / job.area_polygon * 100);
        const gFalha = Math.round((job.area_polygon - (job.spray_in - job.spray_over)) / job.area_polygon * 100);
        const gPerda = Math.round((job.spray_over + (job.spray - job.spray_in)) / job.spray * 100);
        const gVelocidade = Math.round(cVelocidade / tVelocidade * 100);
        const gAltura = Math.round(cAltura / tAltura * 100);
        const gFluxo = Math.round(cFluxo / tFluxo * 100);
        const gTemperatura = Math.round(cTemperatura / tTemperatura * 100);
        const gUmidade = Math.round(cUmidade / tUmidade * 100);
        const gVelocidadeV = Math.round(cVelocidadeV / tVelocidadeV * 100);
        const gDeltaT = Math.round(cDeltaT / tDeltaT * 100);

        return {
            acerto: {
                value: gAcerto,
                alertLevel: this.getAlertLevel('acerto', gAcerto)
            },
            uniformidade: {
                value: gUniformidade,
                alertLevel: this.getAlertLevel('uniformidade', gUniformidade)
            },
            falha: {
                value: gFalha,
                alertLevel: this.getAlertLevel('falha', gFalha)
            },
            perda: {
                value: gPerda,
                alertLevel: this.getAlertLevel('perda', gPerda)
            },
            velocidade: {
                value: gVelocidade,
                alertLevel: this.getAlertLevel('velocidade', gVelocidade)
            },
            altura: {
                value: gAltura,
                alertLevel: this.getAlertLevel('altura', gAltura)
            },
            fluxo: {
                value: gFluxo,
                alertLevel: this.getAlertLevel('fluxo', gFluxo)
            },
            temperatura: {
                value: gTemperatura,
                alertLevel: this.getAlertLevel('temperatura', gTemperatura)
            },
            umidade: {
                value: gUmidade,
                alertLevel: this.getAlertLevel('umidade', gUmidade)
            },
            vento: {
                value: gVelocidadeV,
                alertLevel: this.getAlertLevel('vento', gVelocidadeV)
            },
            deltaT: {
                value: gDeltaT,
                alertLevel: this.getAlertLevel('deltaT', gDeltaT)
            },
        };
    };

    calculateAverageMetrics = () => {
        const jobs = this.state.jobDetail;

        const metricsCounters = {
            acerto: 0,
            uniformidade: 0,
            falha: 0,
            perda: 0,
            velocidade: 0,
            altura: 0,
            fluxo: 0,
            temperatura: 0,
            umidade: 0,
            vento: 0,
            deltaT: 0,
        };

        const totalMetrics = jobs.reduce(
            (acc, job) => {
                const metrics = this.calculateMetrics(job);  // Calcula as métricas com value e alertLevel
                Object.keys(metrics).forEach(key => {
                    const metricValue = metrics[key].value;  // Acessa o valor da métrica
                    if (!isNaN(metricValue) && isFinite(metricValue) && metricValue !== '-') {
                        acc[key] += metricValue;
                        metricsCounters[key] += 1;
                    }
                });
                return acc;
            },
            {
                acerto: 0,
                uniformidade: 0,
                falha: 0,
                perda: 0,
                velocidade: 0,
                altura: 0,
                fluxo: 0,
                temperatura: 0,
                umidade: 0,
                vento: 0,
                deltaT: 0,
            }
        );

        let averageMetrics = {};
        Object.keys(totalMetrics).forEach(key => {
            const count = metricsCounters[key];
            averageMetrics[key] = count === 0 ? 0 : totalMetrics[key] / count;
        });

        return averageMetrics;
    };


    readAlerts = () => {
        const apiUrl = Config.server + `/job_settings`;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt')
            }
        }

        fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                else return res.json();
            })
            .then((result) => {
                const initialAlerts = result[0] && result[0].alerts ? JSON.parse(result[0].alerts) : [];
                this.setState({ selectedAlerts: initialAlerts });
            })
            .catch((error) => {
                if (error.status === 405) {
                    notification.open({
                        message: this.props.res.PERMISSAO,
                        description: this.props.res.PERMISSAO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                } else if (error.status === 401 || error.status === 403) {
                    this.props.link('/login');
                } else {
                    notification.open({
                        message: this.props.res.ERRO,
                        description: this.props.res.ERRO_MSG,
                        icon: <MaterialIcon icon="error" className="text-danger" />
                    });
                }
            });
    };

    renderAlertIcon = (alertLevel) => {
        switch (alertLevel) {
            case 'atencao':
                return <WarningIcon style={{ color: '#e7e700', marginLeft: '0.5rem' }} />;
            case 'erro':
                return <ErrorIcon style={{ color: 'red', marginLeft: '0.5rem' }} />;
            default:
                return null;
        }
    };

    getAlertLevel = (metric, value) => {
        const alertPriority = {
            erro: 3,
            atencao: 2,
            normal: 1
        };

        const alertConfigurations = this.state.selectedAlerts.filter(alert => alert.qualityData === metric);

        let maxAlertLevel = 'normal';

        alertConfigurations.forEach(alert => {
            const minValue = parseFloat(alert.minimum);
            const maxValue = parseFloat(alert.maximum);
            if (value >= minValue && value <= maxValue) {
                if (alertPriority[alert.alertLevel.toLowerCase()] > alertPriority[maxAlertLevel]) {
                    maxAlertLevel = alert.alertLevel.toLowerCase();
                }
            }
        });

        return maxAlertLevel;
    };

    renderDataCell = (dataPoint, value, classes) => {
        const alertLevel = this.getAlertLevel(dataPoint, value);

        return (
            <TableCell className={classes.tableCell}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4rem' }}>
                    {value !== '-' ? `${value}%` : value} {this.renderAlertIcon(alertLevel)}
                </div>
            </TableCell>
        );
    };

    renderSummary = () => {
        const { classes } = this.props;

        const renderSlider = (key, label) => {
            const value = !isNaN(this.state.averageMetrics[key]) ? this.state.averageMetrics[key] : 0;

            return (
                <div>
                    <div className={classes.sliderLabelCustom}>{label}</div>
                    <SliderComponent
                        value={value.toFixed(0)}
                        inverted={key === 'falha' || key === 'perda'}
                    />
                </div>
            );
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '33%', padding: '0 8px' }}>
                    {renderSlider('acerto', 'Acerto')}
                    {renderSlider('uniformidade', 'Uniformidade')}
                    {renderSlider('falha', 'Falha')}
                    {renderSlider('perda', 'Perda')}
                </div>
                <div style={{ width: '33%', padding: '0 8px' }}>
                    {renderSlider('velocidade', 'Velocidade')}
                    {renderSlider('altura', 'Altura')}
                    {renderSlider('fluxo', 'Fluxo')}
                    {renderSlider('temperatura', 'Temperatura')}
                </div>
                <div style={{ width: '33%', padding: '0 8px' }}>
                    {renderSlider('umidade', 'Umidade')}
                    {renderSlider('vento', 'Vento')}
                    {renderSlider('deltaT', 'Delta-T')}
                </div>
            </div>
        );
    };

    getAlertLevelForRow = (metrics) => {
        const alertPriority = {
            erro: 3,
            atencao: 2,
            normal: 1
        };

        let maxAlertLevel = 'normal';

        Object.values(metrics).forEach(metric => {
            if (alertPriority[metric.alertLevel] > alertPriority[maxAlertLevel]) {
                maxAlertLevel = metric.alertLevel;
            }
        });

        return maxAlertLevel;
    };

    render() {
        const { classes, res } = this.props;

        return (
            <div className='dashboard-card'>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    <div className='cardheader'>
                        <AssessmentIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                        <span className='title'>
                            {res.QUALIDADE_}
                        </span>
                    </div>
                    <div className='cardmiddle' style={{ overflow: 'auto', flexDirection: 'column', justifyContent: 'start' }}>
                        <div className={classes.summaryContainer}>
                            {this.renderSummary()}
                        </div>
                        <div>
                            {this.state.isLoading ? (
                                <Skeleton variant="rect" width="100%" height={200} />
                            ) : (
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeadCell}>{res.TRABALHO}</TableCell>
                                            <TableCell className={classes.tableHeadCell}>{res.ACERTO_}</TableCell>
                                            <TableCell className={classes.tableHeadCell}>{res.UNIFORMIDADE}</TableCell>
                                            <TableCell className={classes.tableHeadCell}>{res.FALHA_}</TableCell>
                                            <TableCell className={classes.tableHeadCell}>{res.PERDA}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.jobDetail.map((job) => {
                                            const metrics = this.calculateMetrics(job);
                                            const alertLevel = this.getAlertLevelForRow(metrics);
                                            const backgroundColors = {
                                                normal: 'none',
                                                atencao: 'rgb(255 255 0 / 19%)',
                                                erro: 'rgb(255 0 0 / 29%)'
                                            };
                                            const rowStyle = {
                                                backgroundColor: backgroundColors[alertLevel] || 'none',
                                            };

                                            return (
                                                <TableRow key={job.id} className={classes.tableRow} style={rowStyle}>
                                                    <TableCell className={classes.tableCell}>{job.title}</TableCell>
                                                    {this.renderDataCell('acerto', metrics.acerto.value, classes)}
                                                    {this.renderDataCell('uniformidade', metrics.uniformidade.value, classes)}
                                                    {this.renderDataCell('falha', metrics.falha.value, classes)}
                                                    {this.renderDataCell('perda', metrics.perda.value, classes)}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

DiaryQuality.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DiaryQuality);